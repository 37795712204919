import React, { FC, useCallback } from 'react';
import {
  useStripe,
  useElements,
  CardElement
} from "@stripe/react-stripe-js";

import {
  PrimaryButton,
  Button
} from 'components/atoms';
import { CommonProviderProps } from '../Payment';
import {
  FormLine,
  HR
} from 'theme/mixins';
import { Provider } from 'types/Payment';

import {
  CardNumberWrapper,
  StyledCardNumberInput,
  CardError,
  options,
  TrustImage
} from './Stripe.styles';

interface StripeProps {
  onSubmit?: (stripe: any, elements: any) => void;
};

type Props = CommonProviderProps & StripeProps;

const Stripe: FC<Props> = props => {
  const {
    form,
    mock,
    paymentIntent,
    processing,
    isFull,
    setIsFull,
    isFocused,
    setIsFocused,
    setDisabled,
    error,
    setError,
    beforePay,
    onPostPayment,
    onBack
  } = props;

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = useCallback((event: any) => {
    setIsFull(!event.empty);
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  }, [
    setIsFull,
    setDisabled,
    setError
  ]);

  const onSubmit = useCallback(async () => {
    if (!form || mock) {
      return;
    }

    if (beforePay) {
      try {
        await beforePay();
      } catch (e) {
        return;
      }
    }

    const payload = await stripe!.confirmCardPayment(paymentIntent.data.client_secret, {
      // receipt_email: state.form.billingEmail, // only in live mode
      payment_method: {
        card: elements!.getElement(CardElement)!,
        billing_details: {
          name: form['billing-name'],
          email: form['billing-email'],
          address: {
            line1: form['billing-address-line-1'],
            line2: form['billing-address-line-2'],
            city: form['billing-address-city'],
            postal_code: form['billing-address-postcode']
          },
          phone: form['billing-address-contact-number']
        }
      }
    });

    // payload.paymentIntent.status === succeeded
    // console.log('--confirmCardPayment payload', payload);

    onPostPayment(payload, Provider.Stripe);
  }, [
    form,
    mock,
    stripe,
    elements,
    paymentIntent,
    beforePay,
    onPostPayment
  ]);

  return (
    <>
      <FormLine marginBottom>
        <HR />
      </FormLine>
      <FormLine marginBottom />

      <FormLine
        column
        marginBottom
      >
        <CardNumberWrapper
          isFocused={isFocused}
          isFull={isFull}
        >
          <StyledCardNumberInput
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            options={options}
            onChange={handleChange}
          />
        </CardNumberWrapper>
        {error && (
          <CardError role="alert">{error}</CardError>
        )}
        {paymentIntent.error && (
          <CardError role="alert">{paymentIntent.error}</CardError>
        )}
      </FormLine>

      <FormLine marginBottom />
      <FormLine marginBottom>
        <HR />
      </FormLine>
      <FormLine marginBottom />

      <FormLine row>
        <Button
          type="button"
          style={{
            marginRight: '1rem'
          }}
          onClick={onBack}
        >Back</Button>
        <PrimaryButton
          type="button"
          disabled={!form || !stripe || !elements || error || paymentIntent.error}
          loading={processing}
          style={{ flex: 1 }}
          onClick={onSubmit}
        >Pay</PrimaryButton>
      </FormLine>

      <FormLine
        row
        marginBottom
      >
        <TrustImage
          alt="Powered by Stripe"
          src="/images/payment-providers/stripe.png"
        />
      </FormLine>
    </>
  );
};

export default Stripe;

