import {
  User,
  RoleType,
  AccessMap,
  Access
} from 'types/User';
import { Client } from 'types/Client';
import { Fulfiller } from 'types/Fulfiller';
import { FulfillerGroup } from 'types/FulfillerGroup';
import {
  Job,
  Status,
  Change,
  ChangeType
} from 'types/Job';
import { Event } from 'types/Event';
import {
  DropdownOption
} from 'types/UI';

import { Page, Link, Tag } from '../nav';

// utils
export const isPlatformAdmin = (user: User | Fulfiller | Partial<User> | Partial<Fulfiller>): boolean => {
  return user.role === RoleType.PlatformAdmin;
};

export const isPlatformReviewer = (user: User | Fulfiller | Partial<User> | Partial<Fulfiller>): boolean => {
  return user.role === RoleType.PlatformReviewer;
};

export const isClientAdmin = (user: User | Fulfiller | Partial<User> | Partial<Fulfiller>): boolean => {
  return user.role === RoleType.ClientAdmin;
};

export const isClientReviewer = (user: User | Fulfiller | Partial<User> | Partial<Fulfiller>): boolean => {
  return user.role === RoleType.ClientReviewer;
};

export const isFulfiller = (user: User | Fulfiller | Partial<User> | Partial<Fulfiller>): boolean => {
  return user.role === RoleType.Fulfiller;
};

// export const isCustomer = (user: User | Fulfiller | Partial<User> | Partial<Fulfiller>): boolean => {
//   return user.role === RoleType.Customer;
// };

export const isValidUserAndClient = (
  user: User | Fulfiller | Partial<User> | Partial<Fulfiller> | null,
  clientIdFromUrl: string,
  selectedClient: Client | null
): boolean => {
  if (!user) {
    return false;
  }

  // Platform users
  if (isPlatformAdmin(user) || isPlatformReviewer(user)) {
    if (clientIdFromUrl === 'all') {
      return true;
    }
    else if (selectedClient) {
      return true;
    }
  }

  // Client users
  if (isClientAdmin(user) || isClientReviewer(user) || isFulfiller(user)) {
    return true;
  }

  return false;
};

export const getNavItems = (
  list: Link[],
  user: User | Fulfiller | Partial<User> | Partial<Fulfiller>,
  clientId: string
): Link[] => {
  if (isFulfiller(user)) {
    return list
      .filter((link: Link) => link.tags && link.tags.includes(Tag.Fulfiller))
      .map((link: Link) => {
        if (link.id === Page.Jobs) {
          link.text = `My ${link.text}`;
        }

        return link;
      });
  }
  else if (isPlatformAdmin(user) || isPlatformReviewer(user)) {
    return list
      .filter((link: Link) => {
        if (clientId === 'all') {
          return link.tags && !link.tags.includes(Tag.Client);
        }

        return link;
      });
  }

  return list;
};

export const getRoleOptions = (
  roleMap: any,
  user: User,
  clientSelected: boolean
): DropdownOption[] => {
  return roleMap
    .map((v: any) => ({ label: v, value: v}))
    .filter((d: DropdownOption) => {
      if (isClientAdmin(user) && d.label.includes('Platform')) {
        return false;
      }

      if (isPlatformAdmin(user) && !clientSelected) {
        return d.label.includes('Platform');
      }

      if (isPlatformAdmin(user) && clientSelected && d.label.includes('Platform')) {
        return false;
      }

      return d.label !== RoleType.Fulfiller;
    });
};

export const getClientId = (clientId: string, user: User | Fulfiller | Partial<User> | Partial<Fulfiller>): string => {
  return user.role === RoleType.PlatformAdmin || user.role === RoleType.PlatformReviewer ? 'all' : clientId;
};


// generics
export const canUpdateClient = (
  clientId: string,
  user: User | Fulfiller
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access.clients) || null;

  if (!access) {
    return false;
  }

  if (access.includes('u-own')) {
    return clientId === user.clientId;
  }

  return access.includes('*') || access.includes('u');
};

export const canCreateEvent = (
  clientId: string,
  user: User | Fulfiller
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access.events) || null;

  if (!access) {
    return false;
  }

  return access.includes('*') || access.includes('c');
};

export const canUpdateEvent = (
  clientId: string,
  user: User | Fulfiller,
  event: Event
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access.events) || null;

  if (!access) {
    return false;
  }

  if (access.includes('u-own')) {
    return event.creatorId === user._id;
  }

  return access.includes('*') || access.includes('u');
};

export const canDeleteEvent = (
  clientId: string,
  user: User | Fulfiller,
  event: Event
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access.events) || null;

  if (!access) {
    return false;
  }

  if (access.includes('d-own')) {
    return event.creatorId === user._id;
  }

  return access.includes('*') || access.includes('d');
};

export const canCreateFulfillerGroup = (
  clientId: string,
  user: User | Fulfiller
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access['fulfiller-groups']) || null;

  if (!access) {
    return false;
  }

  return access.includes('*') || access.includes('c');
};

export const canUpdateFulfillerGroup = (
  clientId: string,
  user: User | Fulfiller,
  fulfillerGroup: FulfillerGroup
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access['fulfiller-groups']) || null;

  if (!access) {
    return false;
  }

  if (access.includes('u-own')) {
    return !!fulfillerGroup.fulfillers.find(f => f._id === user._id);
  }

  return access.includes('*') || access.includes('u');
};

export const canDeleteFulfillerGroup = (
  clientId: string,
  user: User | Fulfiller,
  fulfillerGroup: FulfillerGroup
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access['fulfiller-groups']) || null;

  if (!access) {
    return false;
  }

  if (access.includes('d-own')) {
    return !!fulfillerGroup.fulfillers.find(f => f._id === user._id);
  }

  return access.includes('*') || access.includes('d');
};

export const canCreateJob = (
  clientId: string,
  user: User | Fulfiller
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access.jobs) || null;

  if (!access) {
    return false;
  }

  return access.includes('*') || access.includes('c');
};

export const canUpdateJob = (
  clientId: string,
  user: User | Fulfiller,
  job: Job
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access.jobs) || null;

  if (!access) {
    return false;
  }

  if (access.includes('u-own')) {
    return job.fulfillerId === user._id;
  }

  return access.includes('*') || access.includes('u');
};

export const canDeleteJob = (
  clientId: string,
  user: User | Fulfiller,
  job: Job
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access.jobs) || null;

  if (!access) {
    return false;
  }

  if (access.includes('d-own')) {
    return job.fulfillerId === user._id;
  }

  return access.includes('*') || access.includes('d');
};

export const canCreatePricing = (
  clientId: string,
  user: User | Fulfiller
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access.pricing) || null;

  if (!access) {
    return false;
  }

  return access.includes('*') || access.includes('c');
};

export const canReadPricing = (
  clientId: string,
  user: User | Fulfiller
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access.pricing) || null;

  if (!access) {
    return false;
  }

  return access.includes('*') || access.includes('r');
};

export const canUpdatePricing = (
  clientId: string,
  user: User | Fulfiller
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access.pricing) || null;

  if (!access) {
    return false;
  }

  return access.includes('*') || access.includes('u');
};

export const canDeletePricing = (
  clientId: string,
  user: User | Fulfiller
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access.pricing) || null;

  if (!access) {
    return false;
  }

  return access.includes('*') || access.includes('d');
};

export const canCreateService = (
  clientId: string,
  user: User | Fulfiller
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access.services) || null;

  if (!access) {
    return false;
  }

  return access.includes('*') || access.includes('c');
};

export const canUpdateService = (
  clientId: string,
  user: User | Fulfiller
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access.services) || null;

  if (!access) {
    return false;
  }

  return access.includes('*') || access.includes('u');
};

export const canDeleteService = (
  clientId: string,
  user: User | Fulfiller
): boolean => {
  clientId = getClientId(clientId, user);
  const access: Access[] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access && user.permissions.accessMap[clientId].access.services) || null;

  if (!access) {
    return false;
  }

  return access.includes('*') || access.includes('d');
};

export const canCreateUser = (
  clientId: string,
  user: User | Fulfiller,
  targetRoleType?: RoleType
): boolean => {
  clientId = getClientId(clientId, user);
  const rootAccess: AccessMap['client']['access'] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access) || null;

  if (!rootAccess) {
    return false;
  }

  let selector: string = 'users';

  if (targetRoleType === RoleType.Fulfiller) {
    selector = 'fulfillers';
  }

  if (!rootAccess[selector]) {
    return false;
  }

  return rootAccess[selector].includes('*') || rootAccess[selector].includes('c');
};

export const canUpdateUser = (
  clientId: string,
  user: User | Fulfiller,
  targetUser?: User | Fulfiller
): boolean => {
  clientId = getClientId(clientId, user);
  const rootAccess: AccessMap['client']['access'] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access) || null;

  if (!rootAccess) {
    return false;
  }

  let selector: string = 'users';

  if (isFulfiller(user)) {
    selector = 'fulfillers';
  }

  if (!rootAccess[selector]) {
    return false;
  }

  if (rootAccess[selector].includes('u-own') && targetUser) {
    return user._id === targetUser._id;
  }

  return rootAccess[selector].includes('*') || rootAccess[selector].includes('u') || rootAccess[selector].includes('u-own')
};

export const canDeleteUser = (
  clientId: string,
  user: User | Fulfiller,
  targetUser?: User | Fulfiller
): boolean => {
  if (isPlatformAdmin(user) && !targetUser) {
    return false;
  }

  clientId = getClientId(clientId, user);
  const rootAccess: AccessMap['client']['access'] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access) || null;

  if (!rootAccess) {
    return false;
  }

  let selector: string = 'users';

  if (isFulfiller(user)) {
    selector = 'fulfillers';
  }

  if (!rootAccess[selector]) {
    return false;
  }

  if (rootAccess[selector].includes('d-own') && targetUser) {
    return user._id === targetUser._id;
  }

  return rootAccess[selector].includes('*') || rootAccess[selector].includes('d') || rootAccess[selector].includes('d-own');
};


// specifics
export const restrictedProfileEdit = (
  user: User | Fulfiller | Partial<User> | Partial<Fulfiller>,
  field: string
): boolean => {
  if (user.role === RoleType.PlatformAdmin || user.role === RoleType.ClientAdmin) {
    return false;
  }

  if ((field === 'name' || field === 'email' || field === 'priority' || field === 'colour') && (user.role === RoleType.PlatformReviewer || user.role === RoleType.ClientReviewer || user.role === RoleType.Fulfiller)) {
    return true;
  }

  if (field === 'phone' && (user.role === RoleType.PlatformReviewer || user.role === RoleType.ClientReviewer || user.role === RoleType.Fulfiller)) {
    return false;
  }

  return true;
};

export const concealJobPrice = (user: User | Fulfiller | Partial<User> | Partial<Fulfiller>): boolean => {
  return user.role === RoleType.Fulfiller || false;
};

export const canUpdateJobStatus = (
  job: Job | null,
  user: User | Fulfiller | Partial<User> | Partial<Fulfiller> | null,
  isEditMode: boolean
): boolean => {
  if (!job || !user) {
    return false;
  }

  if (!isEditMode) {
    return false;
  }

  switch(user.role) {
    case RoleType.Fulfiller:
    case RoleType.ClientAdmin:
    case RoleType.PlatformAdmin:
      return true;
  };

  return false;
};

export const canRefund = (
  job: Job | null,
  isCreateMode: boolean
): boolean => {
  if (!job) {
    return false;
  }

  if (isCreateMode) {
    return false;
  }

  if (job.status.find(status => {
    if (status.type === 'PAID' || status.type === 'CHARGES_PAID') {
      return true;
    }

    return false;
  })) {
    return true;
  }

  return false;
};

export const canReadPayments = (
  clientId: string,
  user: User | Fulfiller
): boolean => {
  clientId = getClientId(clientId, user);
  const rootAccess: AccessMap['client']['access'] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access) || null;

  if (!rootAccess) {
    return false;
  }

  const selector: string = 'payments';

  if (!rootAccess[selector]) {
    return false;
  }

  return rootAccess[selector].includes('*') || rootAccess[selector].includes('r');
};

export const canUpdateCustomer = (
  clientId: string,
  user: User | Fulfiller
): boolean => {
  clientId = getClientId(clientId, user);
  const rootAccess: AccessMap['client']['access'] | null = (user.permissions.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access) || null;

  if (!rootAccess) {
    return false;
  }

  const selector: string = 'customers';

  if (!rootAccess[selector]) {
    return false;
  }

  return rootAccess[selector].includes('*') || rootAccess[selector].includes('u');
};

export const canHandlePayments = (
  client: Client | null,
  user: User | Fulfiller | Partial<User> | Partial<Fulfiller> | null,
): boolean => {
  if (!client || !user) {
    return false;
  }

  const clientId = getClientId(client._id, user);
  const rootAccess: AccessMap['client']['access'] | null = (user?.permissions?.accessMap && user.permissions.accessMap[clientId] && user.permissions.accessMap[clientId].access) || null;

  if (!rootAccess) {
    return false;
  }

  if (isPlatformAdmin(user) || isPlatformReviewer(user)) {
    return true;
  }

  if (isFulfiller(user)) {
    return client.settings.jobs.fulfillerHandlePayments;
  }

  return true;
};

export const filterBookingChanges = (
  status: Status[],
  user: User | Fulfiller | Partial<User> | Partial<Fulfiller> | null,
): Status[] => {
  if (!user) {
    return [];
  }

  if (concealJobPrice(user)) {
    return status.map((s: Status) => {
      return {
        ...s,
        changes: (s.changes || []).filter((c: Change) => {
          return c.type === ChangeType.JOB_PRICE
        })
      };
    });
  }

  return status;
};

