import React, { memo, useState, useMemo, useCallback } from 'react';

import { DropdownOption } from 'types/UI';

import {
  ModifiedCircle,
  Label,
  Indicators,
  FormFieldWrapper,
  StyledInfo,
  EditWrapper,
  StyledDrag,
  StyledPound,
  StyledIconWrapper,
  InfoWrapper,
  TooltipWrapper,
  WarningWrapper,
  ErrorWrapper,
  StyledEdit,
  StyledWarning,
  StyledError,
  StyledBinIcon,
  ChildrenWrapper
} from './FormField.styles';

export interface FormFieldProps {
  className?: string;
  style?: any;
  children?: JSX.Element;
  warning?: string;
  error?: string;
  id?: string;
  label?: string | JSX.Element;
  hasChanged?: boolean;
  labelInitialHide?: boolean;
  value?: string | number | number[] | boolean | DropdownOption | DropdownOption[] | JSX.Element | undefined | null;
  width?: string;
  widthM?: string;
  widthML?: string;
  widthT?: string;
  widthL?: string;
  height?: string;
  fluidWidth?: string;
  isFocused?: boolean;
  isFull?: boolean;
  noWidth?: boolean;
  noMinWidth?: boolean;
  normalFontSize?: boolean;
  info?: string;        // NOTE: have either info or tooltip but not both
  wordBreak?: boolean;
  tooltip?: string;    // NOTE: have either info or tooltip but not both
  tooltipInfoIcon?: (eventHandler: () => void) => JSX.Element;    // NOTE: have either info or tooltip but not both
  disableAutoComplete?: boolean;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  formFieldStyles?: any;
  builder?: {
    selected?: boolean;
    isSelectable?: boolean;
    isEditable?: boolean;
    isSortable?: boolean;
    isDeletable?: boolean;
    isMultiple?: boolean;
    editWrapperShrinkToFit?: boolean;
    onEdit?: (e: any) => void;
    onDelete?: (e: any) => void;
    onEditWrapperClick?: (e: any) => void;
    onIsMultipleClick?: (e: any) => void;
  }
}

const FormField = React.forwardRef<HTMLDivElement, FormFieldProps>((props, ref) => {
  const {
    className,
    style,
    children,
    warning,
    error,
    label,
    hasChanged,
    width,
    widthM,
    widthML,
    widthT,
    widthL,
    height,
    fluidWidth,
    isFocused,
    isFull,
    noWidth,
    noMinWidth,
    normalFontSize,
    info,
    wordBreak,
    tooltip,
    tooltipInfoIcon,
    builder,
    startAdornment,
    endAdornment,
    formFieldStyles
  } = props;

  const [showMobileTooltip, setShowMobileTooltip] = useState<boolean>(false);
  const formattedError = useMemo(() => {
    return error?.split('').map((char, i) => i === 0 ? char.toUpperCase() : char).join('');
  }, [error]);

  const shouldShowMobileTooltip: boolean = !!tooltip && showMobileTooltip;

  const tooltipIconHandler = useCallback(() => {
    setShowMobileTooltip(prevState => !prevState);
  }, []);

  return (
    <FormFieldWrapper
      ref={ref}
      // className={!tooltip ? className : ''}
      className={className}
      style={{
        ...style,
        ...formFieldStyles
      }}
      changed={hasChanged}
      errored={!!error}
      width={width}
      height={height}
      widthM={widthM}
      widthML={widthML}
      widthT={widthT}
      widthL={widthL}
      fluidWidth={fluidWidth}
      noWidth={noWidth}
      noMinWidth={noMinWidth}
      isSelectable={!!builder && !!builder.isSelectable}
      wordBreak={wordBreak}
      data-title={tooltip || null}
    >
      {builder && (
        <EditWrapper
          isFocused={!!builder.isSelectable && !!builder.selected}
          onClick={builder.onEditWrapperClick}
        >
          {builder.isSortable && (
            <StyledIconWrapper left>
              <StyledDrag />
            </StyledIconWrapper>
          )}
          {builder.isEditable && (
            <StyledIconWrapper centre onClick={builder.onEdit}>
              <StyledEdit />
            </StyledIconWrapper>
          )}
          {builder.isDeletable && (
            <StyledIconWrapper right onClick={builder.onDelete}>
              <StyledBinIcon />
            </StyledIconWrapper>
          )}
          {builder.isMultiple && (
            <StyledIconWrapper centre onClick={builder.onIsMultipleClick}>
              <StyledPound />
            </StyledIconWrapper>
          )}
        </EditWrapper>
      )}
      {label && (
        <Label
          isFocused={isFocused}
          isFull={isFull}
          normalFontSize={normalFontSize}
        >
          <span>
            {label}
          </span>
          <Indicators>
            {hasChanged && <ModifiedCircle />}
            {warning && <StyledWarning />}
            {error && <StyledError />}
            {tooltip && (
              <StyledInfo onClick={tooltipIconHandler}/>
            )}
          </Indicators>
        </Label>
      )}
      {tooltipInfoIcon && tooltipInfoIcon(tooltipIconHandler)}
      {(startAdornment || endAdornment) ? (
        <ChildrenWrapper>
          {startAdornment ? startAdornment : null}
          {children}
          {endAdornment ? endAdornment : null}
        </ChildrenWrapper>
      ) : (
        children
      )}

      {info && (
        <InfoWrapper>
          {info}
        </InfoWrapper>
      )}

      {shouldShowMobileTooltip && (
        <TooltipWrapper>
          {tooltip}
        </TooltipWrapper>
      )}

      {warning && warning.trim().length !== 0 && (
        <WarningWrapper>
          {warning}
        </WarningWrapper>
      )}

      {error && error.trim().length !== 0 && (
        <ErrorWrapper>
          {formattedError}
        </ErrorWrapper>
      )}
    </FormFieldWrapper>
  );
});

export default memo(FormField);

