import React, { FC, useCallback, useState, useEffect, useMemo, useRef, memo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import {
  object,
  string,
  number,
  array
} from 'yup';
import {
  format,
  setHours,
  setMinutes,
  isBefore,
  setDay
} from 'date-fns';

import { gatewayService } from 'services';
import { FrameActions } from 'state';
import {
  Service as ServiceType,
  Field,
  Step,
  FulfillerAssignmentProcess
} from 'types/Service';
import { CellType } from 'types/Header';
import { FulfillerGroup } from 'types/FulfillerGroup';
import { Fulfiller } from 'types/Fulfiller';
import { Client } from 'types/Client';
import { Form } from 'types/UI';
import {
  createDropdownOption,
  getServiceLink,
  isDeepEqual,
  getNetworkErrors,
  isMinWidth,
  getFieldName,
  stripZoneFromISOString
} from 'utils/general';
import {
  BrowserWindow,
  DropdownOption
} from 'types/UI';
import { theme } from 'theme';
import { sizes } from 'theme/media';
import {
  IconButton,
  StyledChevron,
  MenuWrapper,
  MenuItem,
  StyledMenuDots
} from 'theme/mixins';
import {
  PageHeader
} from '../../components';
import {
  useMediaSizes,
  UseMediaSizesState
} from '../../hooks';
import {
  DEFAULT_SERVICE,
  ISO_FORMAT
} from '../../../../../constants';
import {
  TextInput,
  TextArea,
  Dropdown,
  NumericInput,
  Button,
  Spinner,
  PrimaryButton,
  Switch,
  Text,
  Edit,
  Checkbox,
  ModifiedCircle,
  CreatableDropdown
} from 'components/atoms';
import {
  DateTimeFields,
  Popup
} from 'components/molecules';
import { AdminPageProps } from 'components/AppRouter';
import {
  useDebouncedCallback,
  useValidation
} from 'components/hooks';
import {
  canCreateService,
  canUpdateService,
  canReadPricing
} from 'config/privileges';
import {
  NetworkError
} from 'types/Error';
import {
  StepNameModal
} from './modals';
import {
  StyledError,
  Indicators
} from 'components/atoms/form/FormField.styles';

import { BuilderStep } from './BuilderStep';

import {
  Wrapper,
  CreateServiceFormWrapper,
  AdminFormLine,
  StyledStatusIndicator,
  StyledDropdown,
  StyledLinkExternal,
  StyledFrame,
  HR,
  StyledHR,
  Grid,
  ServiceFormWrapper,
  PreviewPane,
  Device,
  FrameSpinner,
  StyledSettingsIcon,
  ModalWrapper,
  ModalItem,
  ModalBorder,
  ModalTable,
  ModalContentWrapper,
  MobileSettingsWrapper,
  MobileSettingIconWrapper,
  IFrameSectionWrapper,
  StyledCross,
  StyledMobileSettingsIcon,
  StyledMobileEditIcon,
  StyledMobileTickIcon,
  StyledMobileChevronIcon,
  ConfigWrapper,
  ConfigCard,
  ConfigCardColumn,
  ConfigDetails,
  ConfigDetailsOuter,
  OperatingHoursWrapper
} from './Service.styles';

interface State {
  service: {
    loading: boolean;
    data: ServiceType | null;
    error: NetworkError | null;
  };
  defaultServiceSteps: {
    loading: boolean;
    data: Step[] | null;
    error: NetworkError | null;
  };
  fields: {
    loading: boolean;
    data: any | null;
    error: NetworkError | null;
  };
  serviceCreate: {
    loading: boolean;
    data: any | null;
    error: NetworkError | null;
  };
  serviceUpdate: {
    loading: boolean;
    data: any | null;
    error: NetworkError | null;
  };
  fulfillerGroupsRepo: {
    loading: boolean;
    data: FulfillerGroup[] | null;
    total: number | null;
    error: NetworkError | null;
  };
  client: {
    loading: boolean;
    data: Client | null;
    error: NetworkError | null;
  };
  form: Form;
  ui: {
    addedFulfillerTypes: DropdownOption[]
  },
  builder: {
    frameLoaded: boolean;
    selectedStepIndex: number;
    selectedFieldIndex: number;
    field: Field | null;
  };
  fulfillerGroups: {
    modalShown: boolean;
  };
  errors: {
    [key: string]: any;
  };
  showMobileSettings: boolean;
  showMobileFieldOptions: boolean;
  steps: {
    showActionMenu: boolean;
    expanded: boolean;
    menuIndex: number;
    createModal: {
      show: boolean;
    };
    renameModal: {
      show: boolean;
      name: string;
      index: number;
    };
    expandedSteps: {
      [id: string]: boolean;
    };
    fieldsAsText: boolean;
  };
  settings: {
    expanded: boolean;
  };
}

const initialState: State = {
  service: {
    loading: false,
    data: null,
    error: null
  },
  defaultServiceSteps: {
    loading: false,
    data: null,
    error: null
  },
  fields: {
    loading: false,
    data: null,
    error: null
  },
  serviceCreate: {
    loading: false,
    data: null,
    error: null
  },
  serviceUpdate: {
    loading: false,
    data: null,
    error: null
  },
  fulfillerGroupsRepo: {
    loading: false,
    data: null,
    total: null,
    error: null
  },
  client: {
    loading: false,
    data: null,
    error: null
  },
  form: {
    name: DEFAULT_SERVICE,
    earliestBookingInDays: 0,
    latestBookingInMonths: 3,
    maxDuration: '2-Hours',
    operatingDays: [0, 1, 1, 1, 1, 1, 0],
    operatingHours: '08:00-19:00',
    bookingTimeIncrementInMinutes: '15',
    paymentReminderInDays: 2
  },
  ui: {
    addedFulfillerTypes: []
  },
  builder: {
    frameLoaded: false,
    selectedStepIndex: -1,
    selectedFieldIndex: -1,
    field: null
  },
  fulfillerGroups: {
    modalShown: false
  },
  errors: {},
  showMobileSettings: false,
  showMobileFieldOptions: false,
  steps: {
    showActionMenu: false,
    expanded: false,
    menuIndex: -1,
    createModal: {
      show: false
    },
    renameModal: {
      show: false,
      name: '',
      index: -1
    },
    expandedSteps: {},
    fieldsAsText: false
  },
  settings: {
    expanded: false
  }
};

const serviceSettingsSchema = object({
  name: string()
    .max(45, 'The name of your service is limited to 45 characters')
    .required(),
  description: string()
    .required(),
  fulfillerLabel: string()
    .label('Fulfiller label')
    .optional(),
  earliestBookingInDays: number()
    .label('Earliest booking')
    .min(0)
    .max(30)
    .optional(),
  latestBookingInMonths: number()
    .label('Latest booking')
    .min(1)
    .max(24)
    .optional(),
  paymentReminderInDays: number()
    .label('Payment reminder')
    .min(2)
    .max(30)
    .required(),
  fulfillerGroups: array()
    .label('Fulfiller group(s)')
    .min(1, 'At least one group must be selected')
    .required(),
  fulfillerPayPercentage: number()
    .label('Fulfiller pay percentage')
    .min(0)
    .max(100)
    .required()
});

const Service: FC<AdminPageProps> = props => {
  const {
    userData,
    addToast
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { clientId, id } = useParams();
  const { t } = useTranslation();

  const urlAction: string | undefined = location.pathname.split('/').pop();
  const isCreateMode: boolean = urlAction === 'create';
  const isEditMode: boolean = urlAction === 'edit';

  const onMediaResize = useCallback(({ isMobile: isM }: UseMediaSizesState): void => {
    const frameData = {
      action: FrameActions.HIDE_BACKGROUND_AND_FOOTER,
      to: 'child',
      payload: {
        service: {
          builder: {
            isEditable: isM,
            hideBackground: isM,
            hideFooter: isM
          }
        }
      }
    };

    throttleIframeMessage(frameData);
  // eslint-disable-next-line
  }, []);

  const { isMobile } = useMediaSizes({ onMediaResize });
  const shouldShowSettingsChevron = useMemo((): boolean => !isMobile && (isCreateMode || isEditMode), [isCreateMode, isEditMode, isMobile]);

  const [state, setState] = useState<State>({
    ...initialState,
    ...(isCreateMode && {
      form: {
        ...initialState.form
      },
      builder: {
        ...initialState.builder
      }
    }),
    ...(!shouldShowSettingsChevron && {
      settings: {
        ...initialState.settings,
        expanded: true
      }
    })
  });
  const {
    errors: serviceSettingsFormErrors,
    validate: validateSettingsForm,
    reset: resetServiceForm
  } = useValidation(serviceSettingsSchema);
  const allStepsCollapsed = useMemo(() => {
    return Object.keys(state.steps.expandedSteps).every((key: string) => {
      return state.steps.expandedSteps[key] === false;
    });
  }, [state.steps.expandedSteps]);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const isSaveButtonDisabled = useMemo((): boolean => {
    if (isCreateMode) {
      return false;
    }

    if (!state.service.data) {
      return false;
    }

    return isDeepEqual(state.form, state.service.data);
  }, [
    isCreateMode,
    state.form,
    state.service.data
  ]);

  const groupDropdownItems = useMemo(() => {
    if (!state.fulfillerGroupsRepo.data) {
      return [];
    }

    return state.fulfillerGroupsRepo.data
      .filter(g => g.fulfillers.length)
      .map(g => createDropdownOption(g.name, g._id));
  }, [
    state.fulfillerGroupsRepo.data
  ]);

  const formattedGroupItemsAsList = useMemo(() => {
    if (isCreateMode || isEditMode || !state.service.data || !state.fulfillerGroupsRepo.data) {
      return [];
    }

    return state.service.data.fulfillerGroups.map((groupId: string) => {
      const fulfillerGroup: FulfillerGroup | undefined = state.fulfillerGroupsRepo.data!.find(g => g._id === groupId);

      if (fulfillerGroup) {
        return fulfillerGroup.name;
      }

      return 'Unknown fulfiller group';
    });
  }, [
    isCreateMode,
    isEditMode,
    state.service.data,
    state.fulfillerGroupsRepo.data 
  ]);

  const valueGroupItems = useMemo(() => {
    if (!state.form.fulfillerGroups || !state.fulfillerGroupsRepo.data) {
      return [];
    }

    return (state.form.fulfillerGroups as Array<string | DropdownOption>).map((item: string | DropdownOption) => {
      if (typeof item === 'string') {
        const fulfillerGroup: FulfillerGroup | undefined = state.fulfillerGroupsRepo.data!.find(g => g._id === item);

        if (fulfillerGroup) {
          return createDropdownOption(fulfillerGroup.name, fulfillerGroup._id);
        }

        return createDropdownOption('Unknown fulfiller group', '');
      }

      return item;
    });
  }, [
    state.form.fulfillerGroups,
    state.fulfillerGroupsRepo.data 
  ]);

  const fetchFields = useCallback(() => {
    if (state.fields.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        loading: true
      }
    }));

    gatewayService.getServiceFields(clientId!)
      .then((fieldsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          fields: {
            loading: false,
            data: fieldsResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          fields: {
            ...prevState.fields,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.fields,
    clientId,
    addToast
  ]);

  const fetchService = useCallback(() => {
    if (state.service.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      service: {
        ...prevState.service,
        loading: true
      }
    }));

    gatewayService.getService(clientId!, id!)
      .then((serviceResponse: any) => {
        setState(prevState => ({
          ...prevState,
          service: {
            loading: false,
            data: serviceResponse.data,
            error: null
          },
          ...(!isCreateMode && {
            form: {
              ...prevState.form,
              ...serviceResponse.data,
            }
          })
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          service: {
            ...prevState.service,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    isCreateMode,
    state.service,
    id,
    clientId,
    addToast
  ]);

  const fetchDefaultServiceSteps = useCallback(() => {
    if (state.defaultServiceSteps.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      defaultServiceSteps: {
        ...prevState.defaultServiceSteps,
        loading: true
      }
    }));

    gatewayService.getDefaultServiceSteps(clientId!)
      .then((defaultServiceStepsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          defaultServiceSteps: {
            loading: false,
            data: defaultServiceStepsResponse.data,
            error: null
          },
          form: {
            ...prevState.form,
            steps: defaultServiceStepsResponse.data
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          defaultServiceSteps: {
            ...prevState.defaultServiceSteps,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.defaultServiceSteps,
    clientId,
    addToast
  ]);

  const createService = useCallback(() => {
    if (state.serviceCreate.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      serviceCreate: {
        ...prevState.serviceCreate,
        loading: true
      }
    }));

    const payload = {
      ...state.form,
      fulfillerGroups: (state.form.fulfillerGroups as DropdownOption[]).map((g: DropdownOption) => g.value)
    };

    gatewayService.createService(clientId!, payload)
      .then((data: any) => {
        setState(prevState => ({
          ...prevState,
          serviceCreate: {
            loading: false,
            data,
            error: null
          }
        }));

        navigate({ pathname: `/${clientId}/back-office/services` });

        addToast({
          type: 'success',
          content: 'Service created',
          tag: 3
        });
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0]

        setState(prevState => ({
          ...prevState,
          serviceCreate: {
            ...prevState.serviceCreate,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.form,
    state.serviceCreate,
    navigate,
    clientId,
    addToast
  ]);

  const updateService = useCallback((updateServiceId: string, payload: any, callback: (e?: any) => void) => {
    if (state.serviceUpdate.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      serviceUpdate: {
        ...prevState.serviceUpdate,
        id: updateServiceId,
        loading: true
      }
    }));

    const {
      pricingRules,
      ...rest
    } = payload;

    payload = {
      ...rest,
      ...(rest.fulfillerGroups && !!rest.fulfillerGroups.find((item: any) => !item.length) && {
        fulfillerGroups: rest.fulfillerGroups.map((g: DropdownOption) => g.value)
      })
    };

    gatewayService.updateService(clientId!, updateServiceId, payload)
      .then((updateServiceResponse: any) => {
        setState(prevState => ({
          ...prevState,
          serviceUpdate: {
            ...prevState.serviceUpdate,
            loading: false,
            data: updateServiceResponse.data,
            error: null
          },
          service: {
            ...prevState.service,
            data: {
              ...updateServiceResponse.data
            }
          }
        }));

        callback()

        addToast({
          type: 'success',
          content: 'Service updated'
        });
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0]

        setState(prevState => ({
          ...prevState,
          serviceUpdate: {
            ...prevState.serviceUpdate,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        callback(err)

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.serviceUpdate,
    clientId,
    addToast
  ]);

  const fetchFulfillerGroups = useCallback(() => {
    if (state.fulfillerGroupsRepo.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      fulfillerGroupsRepo: {
        ...prevState.fulfillerGroupsRepo,
        loading: true
      }
    }));

    gatewayService.getFulfillerGroups(clientId!)
      .then((fulfillerGroupsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          fulfillerGroupsRepo: {
            ...prevState.fulfillerGroupsRepo,
            loading: false,
            total: fulfillerGroupsResponse.total,
            data: fulfillerGroupsResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0]

        setState(prevState => ({
          ...prevState,
          fulfillerGroupsRepo: {
            ...prevState.fulfillerGroupsRepo,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.fulfillerGroupsRepo,
    clientId,
    addToast
  ]);

  const fetchClient = useCallback(() => {
    if (state.client.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      client: {
        ...prevState.client,
        loading: true
      }
    }));

    gatewayService.getClient(clientId!)
      .then((clientResponse: any) => {
        setState(prevState => ({
          ...prevState,
          client: {
            loading: false,
            data: clientResponse.data,
            error: null
          },
          form: {
            ...prevState.form,
            fulfillerLabel: clientResponse.data.settings.jobs.defaultFulfillerLabel,
            vat: clientResponse.data.settings.accounting.defaultVat
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          client: {
            ...prevState.client,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.client,
    clientId,
    addToast
  ]);

  const removeField = useCallback((stepIndex: number, fieldIndex: number) => {
    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        steps: [
          ...(prevState.form.steps as any).slice(0, stepIndex),
          {
            ...(prevState.form.steps as any)[stepIndex],
            fields: [
              ...(prevState.form.steps as any)[stepIndex].fields.slice(0, fieldIndex),
              ...(prevState.form.steps as any)[stepIndex].fields.slice(fieldIndex + 1)
            ]
          },
          ...(prevState.form.steps as any).slice(stepIndex + 1)
        ]
      },
      ...(prevState.builder.selectedFieldIndex === fieldIndex && {
        builder: {
          ...prevState.builder,
          selectedFieldIndex: -1,
          field: null
        }
      })
    }));
  }, []);

  const selectField = useCallback((stepIndex: number, fieldIndex: number, field: Field | null) => {
    // console.log('-----selectField', field);

    if (!field) {
      setState(prevState => ({
        ...prevState,
        builder: {
          ...prevState.builder,
          selectedStepIndex: -1,
          selectedFieldIndex: -1,
          field: null
        }
      }));

      return;
    }

    const { value, ...fieldRest } = field;

    setState(prevState => {
      const step = (prevState.form.steps as any)[stepIndex];

      return {
        ...prevState,
        builder: {
          ...prevState.builder,
          selectedStepIndex: stepIndex,
          selectedFieldIndex: fieldIndex,
          field: {
            ...fieldRest
          }
        },
        steps: {
          ...prevState.steps,
          expandedSteps: {
            ...prevState.steps.expandedSteps,
            [step.name]: true
          }
        }
      };
    });
  }, []);

  const editField = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      showMobileFieldOptions: true
    }));
  }, []);

  const reOrderFields = useCallback((
    stepIndex: number,
    dragIndex: number,
    hoverIndex: number
  ) => {
    setState(prevState => {
      const step = (prevState.form.steps as any)[stepIndex];
      const fields = [ ...step.fields ];
      const dragField = fields[dragIndex];

      fields.splice(dragIndex, 1);
      fields.splice(hoverIndex, 0, dragField);

      return {
        ...prevState,
        form: {
          ...prevState.form,
          steps: [
            ...(prevState.form.steps as any).slice(0, stepIndex),
            {
              ...step,
              fields
            },
            ...(prevState.form.steps as any).slice(stepIndex + 1)
          ]
        }
      };
    });
  }, []);

  const changeFieldToNewStep = useCallback((
    sourceStepIndex: number,
    targetStepIndex: number,
    fieldIndex: number
  ) => {
    setState(prevState => {
      const newSteps = [ ...(prevState.form.steps as any) ];
      const sourceStep = newSteps[sourceStepIndex];
      const targetStep = newSteps[targetStepIndex];
      const dragField = sourceStep.fields[fieldIndex];
      const dragDirection: number = targetStepIndex > sourceStepIndex ? 1 : -1;

      sourceStep.fields.splice(fieldIndex, 1);

      if (dragDirection === 1) {
        targetStep.fields.unshift(dragField);
      } else {
        targetStep.fields.push(dragField);
      }

      newSteps[sourceStepIndex] = { ...sourceStep };
      newSteps[targetStepIndex] = { ...targetStep };

      const newFieldIndex: number = dragDirection === 1 ? 0 : targetStep.fields.length - 1;

      return {
        ...prevState,
        form: {
          ...prevState.form,
          steps: newSteps
        },
        steps: {
          ...prevState.steps,
          expandedSteps: {
            ...prevState.steps.expandedSteps,
            [targetStep.name]: true
          }
        },
        builder: {
          ...prevState.builder,
          selectedStepIndex: targetStepIndex,
          selectedFieldIndex: newFieldIndex,
          field: dragField
        }
      };
    });
  }, []);

  const toggleExpandAllSteps = useCallback(() => {
    setState(prevState => {
      const expandedMap = (prevState.form.steps as any).reduce((acc: any, curr: Step) => {
        if (!acc[curr.name]) {
          acc[curr.name] = allStepsCollapsed;
        }

        return acc;
      }, {});

      return {
        ...prevState,
        steps: {
          ...prevState.steps,
          expandedSteps: {
            ...expandedMap
          },
          ...(!prevState.steps.expanded && {
            expanded: true
          })
        }
      };
    });
  }, [allStepsCollapsed]);

  const toggleStepExpansion = useCallback((stepId: string) => {
    setState(prevState => ({
      ...prevState,
      steps: {
        ...prevState.steps,
        expandedSteps: {
          ...prevState.steps.expandedSteps,
          [stepId]: !prevState.steps.expandedSteps[stepId]
        }
      }
    }));
  }, []);

  const onManagePricingRules = useCallback(() => {
    navigate({
      pathname: `/${clientId}/back-office/pricing-rules`,
      search: `?service=${id}`
    });
  }, [id, clientId, navigate]);

  // Asynchronous for formality to conform with promise.all API
  const operatingHoursValid = useCallback((): Promise<void> => {
    return new Promise((resolve, reject) => {
      const hoursSplit: string[] = (state.form.operatingHours as string)?.split('-');
      const startSplit: string[] = hoursSplit[0].split(':');
      const endSplit: string[] = hoursSplit[1].split(':');
      const start = setMinutes(setHours(new Date(), Number(startSplit[0])), Number(startSplit[1]));
      const end = setMinutes(setHours(new Date(), Number(endSplit[0])), Number(endSplit[1]));

      if (isBefore(end, start)) {
        setState(prevState => {
          const newState = { ...prevState };

          newState.errors.operatingHours = 'Operation end time cannot be before start time';

          return newState;
        });

        return reject();
      }
      else if (end === start) {
        setState(prevState => {
          const newState = { ...prevState };

          newState.errors.operatingHours = 'Operation end time cannot be the same as start time';

          return newState;
        });

        return reject();
      } else {
        setState(prevState => {
          const newState = { ...prevState };

          delete newState.errors.operatingHours;

          return newState;
        });

        return resolve();
      }
    });
  }, [
    state.form,
  ]);

  const onCancel = useCallback(() => {
    resetServiceForm();

    if (isCreateMode) {
      navigate({
        pathname: `/${clientId}/back-office/services`
      });
    }
    else if (isEditMode && id) {
      navigate({
        pathname: `/${clientId}/back-office/services/${id}`
      });
    }
  }, [
    id,
    clientId,
    isEditMode,
    isCreateMode,
    navigate,
    resetServiceForm
  ]);

  const onFormSubmit = useCallback((e: any) => {
    e.preventDefault();

    Promise.all([
      operatingHoursValid(),
      validateSettingsForm({
        form: state.form,
        all: true,
        propagateRejection: true
      })
    ])
      .then(() => {
        if (isSaveButtonDisabled) {
          return;
        }

        if (isCreateMode) {
          createService();
        }
        else if (isEditMode) {
          const {
            _id,
            created,
            updated,
            ...rest
          } = state.form;

          updateService(id!, rest, (err?: any) => {
            if (!err) {
              navigate({ pathname: `/${clientId}/back-office/services/${id}` });
            }
          });
        }
      })
      .catch(() => {
        addToast({
          type: 'error',
          content: 'Please ensure all required fields are filled in correctly',
          autoRemove: true
        });
      });
  }, [
    navigate,
    id,
    clientId,
    isCreateMode,
    isEditMode,
    state.form,
    addToast,
    createService,
    updateService,
    isSaveButtonDisabled,
    validateSettingsForm,
    operatingHoursValid
  ]);

  const onChangeFormField = useCallback((e: any, key: string, value?: any) => {
    const localValue = value || value === 0 ? value : e.target.value;

    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [key]: localValue
      }
    }));
  }, []);

  const onChangeFormFieldBoolean = useCallback((newBoolState: boolean, key: string) => {
    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [key]: newBoolState
      }
    }));
  }, []);

  const addNewField = useCallback((stepIndex: number, field: Field) => {
    setState(prevState => {
      const step = (prevState.form.steps as any)[stepIndex];

      const newState = {
        ...prevState,
        form: {
          ...prevState.form,
          steps: [
            ...(prevState.form.steps as any).slice(0, stepIndex),
            {
              ...(prevState.form.steps as any)[stepIndex],
              fields: [
                ...(prevState.form.steps as any)[stepIndex].fields,
                { ...field }
              ]
            },
            ...(prevState.form.steps as any).slice(stepIndex + 1)
          ]
        },
        steps: {
          ...prevState.steps,
          expandedSteps: {
            ...prevState.steps.expandedSteps,
            [step.name]: true
          }
        },
      };

      const newFieldIndex: number = newState.form.steps[stepIndex].fields.findIndex((f: Field) => f.id === field.id);

      newState.builder = {
        ...prevState.builder,
        selectedStepIndex: stepIndex,
        selectedFieldIndex: newFieldIndex,
        field
      };

      return newState;
    });
  }, []);

  const [editNewField] = useDebouncedCallback((stepIndex: number, fieldIndex: number, field: Field | Partial<Field>) => {
    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        steps: [
          ...(prevState.form.steps as any).slice(0, stepIndex),
          {
            ...(prevState.form.steps as any)[stepIndex],
            fields: [
              ...(prevState.form.steps as any)[stepIndex].fields.slice(0, fieldIndex),
              {
                ...field
              },
              ...(prevState.form.steps as any)[stepIndex].fields.slice(fieldIndex + 1)
            ]
          },
          ...(prevState.form.steps as any).slice(stepIndex + 1)
        ]
      }
    }));
  }, 500);

  const selectStep = useCallback((index: number) => {
    setState(prevState => ({
      ...prevState,
      builder: {
        ...prevState.builder,
        selectedStepIndex: index,
        selectedFieldIndex: -1,
        field: null
      }
    }));
  }, []);

  const deleteStep = useCallback((index: number) => {
    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        steps: [
          ...(prevState.form.steps as any).slice(0, index),
          ...(prevState.form.steps as any).slice(index + 1)
        ]
      }
    }));
  }, []);

  const onIframeMessage = useCallback((event: any) => {
    if (event.origin === window.location.origin) {
      let data = null;

      try {
        data = JSON.parse(event.data);
      } catch (e) {};

      if (!data) {
        return;
      }

      if (data.to === 'parent') {
        // console.log('--------Parent', data);

        switch (data.action) {
          case FrameActions.SELECT_STEP: {
            const stepIndex = data.payload.stepIndex;

            selectStep(stepIndex);
            break;
          }
          case FrameActions.SELECT_FIELD:
          case FrameActions.EDIT_FIELD: {
            const stepIndex = data.payload.selectedStepIndex;
            const fieldIndex = data.payload.selectedFieldIndex;
            const field = data.payload.field;

            selectField(stepIndex, fieldIndex, field);

            if (data.action === FrameActions.EDIT_FIELD) {
              editField();
            }

            break;
          }
          case FrameActions.DELETE_FIELD: {
            const stepIndex = data.payload.selectedStepIndex;
            const fieldIndex = data.payload.selectedFieldIndex;

            removeField(stepIndex, fieldIndex);
            break;
          }
          case FrameActions.REORDER_FIELDS: {
            const stepIndex = data.payload.stepIndex;
            const dragIndex = data.payload.dragIndex;
            const hoverIndex = data.payload.hoverIndex;

            reOrderFields(stepIndex, dragIndex, hoverIndex);
            break;
          }
          case FrameActions.REMOVE_STEP: {
            const stepIndex = data.payload.stepIndex;

            deleteStep(stepIndex);
            break;
          }
        }
      }
    }
  }, [
    selectStep,
    selectField,
    editField,
    removeField,
    reOrderFields,
    deleteStep
  ]);

  const addMessageListener = useCallback(() => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow!.addEventListener('message', onIframeMessage);
    }
  }, [onIframeMessage]);

  const sendInitialDataToFrame = useCallback(() => {
    const frameData = {
      action: FrameActions.UPDATE_CHILD,
      to: 'child',
      payload: {
        service: {
          data: {
            ...state.service.data,
            ...state.form,
            ...(isCreateMode && {
              _id: 'newService',
              clientId
            })
          },
          // TODO: use initialState from state.ts
          builder: {
            context: 'service',
            // selectedFields: state.builder.selectedFields,
            selectedStepIndex: state.builder.selectedStepIndex,
            selectedFieldIndex: state.builder.selectedFieldIndex,
            selectedFields: [],
            field: state.builder.field,
            isSelectable: true,
            isEditable: true,
            isSortable: true,
            isDeletable: true,
            hideBackground: !isMinWidth(sizes.tablet),
            hideFooter: !isMinWidth(sizes.tablet)
          }
        }
      }
    };

    if (iframeRef.current) {
      (iframeRef.current!.contentWindow! as BrowserWindow).isChild = true;
      (iframeRef.current!.contentWindow! as BrowserWindow).isModifiable = isCreateMode || isEditMode;
      iframeRef.current!.contentWindow!.postMessage(JSON.stringify(frameData), window.location.origin);
    }
  }, [
    isCreateMode,
    isEditMode,
    clientId,
    state.form,
    state.builder.selectedStepIndex,
    state.builder.selectedFieldIndex,
    state.builder.field,
    state.service.data
  ]);

  const onFrameLoad = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      builder: {
        ...prevState.builder,
        frameLoaded: true
      }
    }));

    addMessageListener();
    sendInitialDataToFrame();
  }, [
    addMessageListener,
    sendInitialDataToFrame
  ]);

  const openFulfillerGroupsModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      fulfillerGroups: {
        ...prevState,
        modalShown: true
      }
    }));
  }, []);

  const closeFulfillerGroupsModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      fulfillerGroups: {
        ...initialState.fulfillerGroups
      }
    }));
  }, []);

  const closeNameModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      steps: {
        ...prevState.steps,
        createModal: {
          show: false
        },
        renameModal: {
          ...initialState.steps.renameModal
        }
      }
    }));
  }, []);

  const upsertStep = useCallback((form: any, index?: number) => {
    const newStepName: string = getFieldName(form.name);

    if (index! >= 0) {
      setState(prevState => {
        const steps = [
          ...(prevState.form.steps as any).slice(0, index),
          {
            ...(prevState.form.steps as any)[index!],
            name: newStepName,
            label: form.name
          },
          ...(prevState.form.steps as any).slice(index! + 1)
        ];

        return {
          ...prevState,
          form: {
            ...prevState.form,
            steps
          },
          // TODO: finish, set selectedStep to the one being created/edited
          builder: {
            ...prevState.builder,
            selectedStepIndex: index!
          }
        };
      });
    } else {
      setState(prevState => {
        const firstFixedStepIndex: number = (prevState.form.steps as any).findIndex((s: Step) => s.isFixed);

        const newState = { ...prevState };

        (newState.form.steps as any).splice(firstFixedStepIndex, 0, {
          name: newStepName,
          label: form.name,
          fields: []
        });

        const newStepIndex: number = (newState.form.steps as any).findIndex((s: Step) => s.name === newStepName);

        newState.builder = {
          ...prevState.builder,
          selectedStepIndex: newStepIndex,
          selectedFieldIndex: -1,
          field: null
        };

        newState.steps.expanded = true;

        return newState;
      });
    }
  }, []);

  const reOrderSteps = useCallback((
    dragIndex: number,
    hoverIndex: number
  ) => {
    setState(prevState => {
      const steps = [ ...(prevState.form.steps as any) ];
      const dragStep = steps[dragIndex];
      const hoverStep = steps[hoverIndex];

      if (dragStep.isFixed || hoverStep.isFixed) {
        return prevState;
      }

      steps.splice(dragIndex, 1);
      steps.splice(hoverIndex, 0, dragStep);

      return {
        ...prevState,
        form: {
          ...prevState.form,
          steps: [ ...steps ]
        },
        builder: {
          ...prevState.builder,
          selectedStepIndex: hoverIndex
        }
      };
    });
  }, []);

  const [validateName] = useDebouncedCallback((e: any, service: ServiceType | null) => {
    const name: string = e.target.value.trim();

    gatewayService
      .getServices(clientId!)
      .then((servicesResponse: any) => {
        // TODO; check that the component is still mounted otherwise exit

        const serviceNames: string[] = servicesResponse.data
          .filter((s: ServiceType) => {
            if (!service) {
              return true;
            }

            return s._id !== service._id
          })
          .map((s: ServiceType) => s.name);

        setState(prevState => {
          const nameTaken: boolean = serviceNames.includes(name);

          const newState = {
            ...prevState,
            errors: {
              ...prevState.errors,
              ...(nameTaken && {
                name: 'This name has already been taken'
              })
            }
          };

          if (!nameTaken) {
            delete newState.errors.name;
          }

          return newState;
        });
      })
      .catch(() => {});
  }, 1000);

  const renderSettings = useCallback(() => {
    const formKeys = Object
      .keys(state.fields.data)
      .filter(key => key !== 'fulfillerAssignmentProcess'
        && key !== 'isEnabled'
        && key !== 'isDeleted'
      );

    return (
      <ConfigCard
        boxShadow
        centerV
        column
      >
        <AdminFormLine>
          {shouldShowSettingsChevron && (
            <ConfigCardColumn className={'mobile-settings-chevron-button'}>
              <IconButton
                hoverEffect
                type="button"
                onClick={(e: any) => {
                  setState(prevState => ({
                    ...prevState,
                    settings: {
                      ...prevState.settings,
                      expanded: !prevState.settings.expanded
                    }
                  }));
                }}
              >
                <StyledChevron $down={state.settings.expanded} />
              </IconButton>
            </ConfigCardColumn>
          )}
          <ConfigCardColumn>
            <span>Settings</span>
          </ConfigCardColumn>
        </AdminFormLine>
        <ConfigDetails isExpanded={state.settings.expanded}>
          <ConfigDetailsOuter>
            <ServiceFormWrapper>
              {formKeys.map((key: string, index: number) => {
                const fieldItem = state.fields.data[key];
                let value = (isCreateMode || isEditMode ? state.form[key] : (state.service.data as any)[key]);
                let databaseValue = (state.service.data && (state.service.data as any)[key]) || null;
                let hasChanged: boolean = isEditMode && value !== databaseValue;

                let elem: React.ReactNode;

                if (!isCreateMode && !isEditMode && key === 'name') {
                  return null;
                }

                switch (fieldItem.type) {
                  case 'boolean':
                    if (!isCreateMode && !isEditMode) {
                      elem = (
                        <Text
                          value={value ? 'Yes' : 'No'}
                          label={t(key)}
                        />
                      );
                    } else {
                      value = (isCreateMode || isEditMode ? state.form[key] : (state.service.data as any)[key]) || false;
                      databaseValue = (state.service.data && (state.service.data as any)[key]) || false;
                      hasChanged = isEditMode && value !== databaseValue;

                      elem = (
                        <Switch
                          hasChanged={hasChanged}
                          value={value}
                          label={t(key)}
                          info={fieldItem.info}
                          tooltip={fieldItem.tooltip}
                          onChange={(newState: boolean) => onChangeFormFieldBoolean(newState, key)}
                        />
                      );
                    }
                    break;
                  case 'string':
                    if (!isCreateMode && !isEditMode) {
                      if (key === 'maxDuration') {
                        value = value.toLowerCase().replace('-', ' ');
                      }

                      if (key === 'operatingHours') {
                        value = value.split('-').join(' - ');
                      }

                      elem = (
                        <Text
                          value={value}
                          label={t(key)}
                        />
                      );
                    } else {
                      if (key === 'maxDuration') {
                        const val: string = value || '';
                        const valSplit: string[] = val.split('-');
                        const numericVal: number | undefined = Number(valSplit[0]) || undefined;
                        const dropdownVal = valSplit[1] || '';

                        elem = (
                          <AdminFormLine column>
                            <h5
                              style={{
                                fontSize: '1.2rem',
                                marginBottom: '.5rem'
                              }}
                            >
                              <span>{t(key)}</span>
                              {hasChanged && (
                                <ModifiedCircle leftMargin />
                              )}
                            </h5>
                            <AdminFormLine>
                              <NumericInput
                                value={numericVal}
                                label={'Amount'}
                                min={0}
                                max={60}
                                placeholder={fieldItem.hint && fieldItem.hint.split('-')[0]}
                                onUpdate={(e, newVal) => {
                                  setState(prevState => {
                                    const currDropdownVal: string = prevState.form[key] ? (prevState.form[key] as string).split('-')[1] || '' : 'Hours';

                                    return {
                                      ...prevState,
                                      form: {
                                        ...prevState.form,
                                        [key]: `${newVal}-${currDropdownVal}`
                                      }
                                    };
                                  });
                                }}
                                onKeyUp={(e) => {
                                  if (e.key === 'Enter') {
                                    onFormSubmit(window.event);
                                  }
                                }}
                              />
                              <StyledDropdown
                                label={'Unit of time'}
                                options={['Days', 'Hours', 'Minutes'].map(unit => createDropdownOption(unit))}
                                value={dropdownVal}
                                placeholder={fieldItem.hint && fieldItem.hint.split('-')[1]}
                                onChange={(option: any) => {
                                  setState(prevState => {
                                    const currNumericVal: number = Number(((prevState.form[key] as string) || '').split('-')[0]) || 0;

                                    return {
                                      ...prevState,
                                      form: {
                                        ...prevState.form,
                                        [key]: `${currNumericVal}-${(option && option.value) || ''}`
                                      }
                                    };
                                  });
                                }}
                                style={{
                                  marginLeft: '1rem'
                                }}
                              />
                            </AdminFormLine>
                          </AdminFormLine>
                        );
                      }
                      else if (key === 'operatingHours') {
                        const val: string = value || '';
                        const valSplit: string[] = val.split('-').length === 1 ? [] : val.split('-');
                        const nowDateSegment: string = format(new Date(), ISO_FORMAT).split('T')[0];
                        const startValue: string | undefined = valSplit.length === 2 ? `${nowDateSegment}T${valSplit[0]}:00.000Z` : undefined;
                        const endValue: string | undefined = valSplit.length === 2 ? `${nowDateSegment}T${(valSplit[1])}:00.000Z` : undefined;
                        const startPlaceholder: string = fieldItem.hint && fieldItem.hint.split('-')[0];
                        const endPlaceholder: string = fieldItem.hint && fieldItem.hint.split('-')[1];

                        elem = (
                          <AdminFormLine column>
                            <h5
                              style={{
                                display: 'flex',
                                fontSize: '1.2rem',
                                marginBottom: '.5rem'
                              }}
                            >
                              <span>{t(key)}</span>
                              <Indicators>
                                {hasChanged && ( <ModifiedCircle />)}
                                {state.errors.operatingHours && <StyledError />}
                              </Indicators>
                            </h5>
                            <OperatingHoursWrapper row centerV>
                              <AdminFormLine column>
                                <h5>Start</h5>
                                <AdminFormLine>
                                  <DateTimeFields
                                    value={new Date(stripZoneFromISOString(startValue))}
                                    timeOnly
                                    noMarginBottom
                                    timePlaceholder={startPlaceholder}
                                    onChange={(datetime: Date | string[]) => {
                                      setState(prevState => {
                                        const currVal = (prevState.form[key] as string) || '';
                                        const currValSplit: string[] = currVal.split('-').length === 1 ? [] : currVal.split('-');
                                        const timeSegment: string = format(datetime as Date, 'HH:mm');

                                        const timeSplit: string[] = timeSegment.split(':');
                                        const hour: string = timeSplit[0];
                                        const minute: string = timeSplit[1];

                                        return {
                                          ...prevState,
                                          form: {
                                            ...prevState.form,
                                            [key]: `${hour}:${minute}-${currValSplit[1] || endPlaceholder}`
                                          }
                                        };
                                      });
                                    }}
                                    onBlur={() => operatingHoursValid().catch(() => {}) }
                                  />
                                </AdminFormLine>
                              </AdminFormLine>
                              <span style={{marginTop: '3rem'}}>&nbsp;&nbsp;-&nbsp;&nbsp;</span>
                              <AdminFormLine column>
                                <h5>Finish</h5>
                                <AdminFormLine>
                                  <DateTimeFields
                                    value={new Date(stripZoneFromISOString(endValue))}
                                    timeOnly
                                    noMarginBottom
                                    timePlaceholder={endPlaceholder}
                                    onChange={(datetime: Date | string []) => {
                                      setState(prevState => {
                                        const currVal = (prevState.form[key] as string) || '';
                                        const currValSplit: string[] = currVal.split('-').length === 1 ? [] : currVal.split('-');
                                        const timeSegment: string = format(datetime as Date, 'HH:mm');

                                        const timeSplit: string[] = timeSegment.split(':');
                                        const hour: string = timeSplit[0];
                                        const minute: string = timeSplit[1];

                                        return {
                                          ...prevState,
                                          form: {
                                            ...prevState.form,
                                            [key]: `${currValSplit[0] || startPlaceholder}-${hour}:${minute}`
                                          }
                                        };
                                      });
                                    }}
                                    onBlur={() => operatingHoursValid().catch(() => {}) }
                                  />
                                </AdminFormLine>
                              </AdminFormLine>
                            </OperatingHoursWrapper>
                            {state.errors.operatingHours && (
                              <Text error={state.errors.operatingHours} />
                            )}
                          </AdminFormLine>
                        );
                      }
                      else if (key === 'name') {
                        value = value || '';
                        databaseValue = (state.service.data && (state.service.data as any)[key]) || '';
                        hasChanged = isEditMode && value !== databaseValue;

                        elem = (
                          <TextInput
                            hasChanged={hasChanged}
                            widthM={'100%'}
                            widthT={'35rem'}
                            value={value}
                            label={t(key)}
                            info={fieldItem.info}
                            placeholder={fieldItem.hint}
                            error={serviceSettingsFormErrors[key] || state.errors[key]}
                            maxLength={50}
                            tooltip={fieldItem.tooltip}
                            onChange={(e) => {
                              onChangeFormField(e, key);
                              validateName(e, state.service.data);
                            }}
                            onKeyUp={(e) => {
                              if (e.key === 'Enter') {
                                onFormSubmit(window.event);
                              }
                            }}
                            onBlur={() => validateSettingsForm({
                              form: state.form,
                              field: key
                            })}
                          />
                        );
                      } else {
                        value = value || '';
                        databaseValue = (state.service.data && (state.service.data as any)[key]) || '';
                        hasChanged = isEditMode && value !== databaseValue;

                        elem = (
                          <TextInput
                            hasChanged={hasChanged}
                            widthM={'100%'}
                            widthT={'35rem'}
                            value={value}
                            error={serviceSettingsFormErrors[key]}
                            label={t(key)}
                            info={fieldItem.info}
                            placeholder={fieldItem.hint}
                            tooltip={fieldItem.tooltip}
                            onChange={(e) => onChangeFormField(e, key)}
                            onKeyUp={(e) => {
                              if (e.key === 'Enter') {
                                onFormSubmit(window.event);
                              }
                            }}
                            onBlur={() => validateSettingsForm({
                              form: state.form,
                              field: key
                            })}
                          />
                        );
                      }
                    }
                    break;
                  case 'long-string':
                    if (!isCreateMode && !isEditMode) {
                      elem = (
                        <Text
                          value={value}
                          label={t(key)}
                        />
                      );
                    } else {
                      value = value || '';
                      databaseValue = (state.service.data && (state.service.data as any)[key]) || '';
                      hasChanged = isEditMode && value !== databaseValue;

                      elem = (
                        <TextArea
                          hasChanged={hasChanged}
                          widthM={'100%'}
                          widthT={'35rem'}
                          value={value}
                          error={serviceSettingsFormErrors[key]}
                          label={t(key)}
                          info={fieldItem.info}
                          placeholder={fieldItem.hint}
                          tooltip={fieldItem.tooltip}
                          onChange={(e) => onChangeFormField(e, key)}
                          onBlur={() => validateSettingsForm({
                            form: state.form,
                            field: key
                          })}
                        />
                      );
                    }
                    break;
                  case 'number':
                  case 'float':
                    if (!isCreateMode && !isEditMode) {
                      elem = (
                        <Text
                          value={value}
                          label={t(key)}
                        />
                      );
                    } else {
                      let extras: any = {};
                      value = !isNaN(value) ? value : 0;
                      databaseValue = (state.service.data && (state.service.data as any)[key]) || 0;
                      hasChanged = isEditMode && value !== databaseValue;

                      if (key === 'earliestBookingInDays') {
                        extras = {
                          min: 0,
                          max: 30
                        };
                      }
                      if (key === 'latestBookingInMonths') {
                        extras = {
                          min: 1,
                          max: 24
                        };
                      }
                      if (key === 'fulfillerPayPercentage') {
                        extras = {
                          min: 0,
                          max: 100,
                          step: 0.01
                        };
                      }

                      elem = (
                        <NumericInput
                          {...extras}
                          hasChanged={hasChanged}
                          value={value}
                          error={serviceSettingsFormErrors[key]}
                          label={t(key)}
                          info={fieldItem.info}
                          placeholder={fieldItem.hint}
                          tooltip={fieldItem.tooltip}
                          onUpdate={(e, val: number) => {
                            setState(prevState => ({
                              ...prevState,
                              form: {
                                ...prevState.form,
                                [key]: Number(val.toFixed(2))
                              }
                            }));
                          }}
                          onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                              onFormSubmit(window.event);
                            }
                          }}
                          onBlur={() => validateSettingsForm({
                            form: state.form,
                            field: key
                          })}
                        />
                      );
                    }
                    break;
                  case 'enum':
                    if (!isCreateMode && !isEditMode) {
                      elem = (
                        <Text
                          value={value}
                          label={t(key)}
                        />
                      );
                    } else {
                      if (key === 'fulfillerLabel') {
                        const fulfillerLabelOptions = [
                          ...fieldItem.enum.map((o: string) => createDropdownOption(o)),
                          ...state.ui.addedFulfillerTypes
                        ];

                        if (state.client.data) {
                          const globalSelectedOption: string = state.client.data?.settings.jobs.defaultFulfillerLabel;
                          const optionIndex: number = fulfillerLabelOptions.findIndex(o => o.label === globalSelectedOption);

                          if (optionIndex === -1) {
                            fulfillerLabelOptions.push(createDropdownOption(globalSelectedOption));
                          }
                        }

                        fulfillerLabelOptions.sort((a, b) => a.label.localeCompare(b.label));

                        elem = (
                          <CreatableDropdown
                            widthM={'100%'}
                            widthT={'35rem'}
                            hasChanged={hasChanged}
                            label={t(key)}
                            value={value}
                            error={serviceSettingsFormErrors[key]}
                            info={fieldItem.info}
                            placeholder={fieldItem.hint}
                            tooltip={fieldItem.tooltip}
                            options={fulfillerLabelOptions}
                            onChange={(option: any, actionMeta) => {
                              setState(prevState => {
                                const newState = {
                                  ...prevState,
                                  form: {
                                    ...prevState.form,
                                    [key]: option.value || ''
                                  }
                                };

                                if (actionMeta.action === 'create-option') {
                                  const newOptionExists: boolean = newState.ui.addedFulfillerTypes.findIndex(ft => ft.label === value) !== -1;

                                  if (!newOptionExists) {
                                    newState.ui.addedFulfillerTypes.push(actionMeta.option as DropdownOption);
                                  }
                                }

                                return newState;
                              });
                            }}
                            onBlur={() => validateSettingsForm({
                              form: state.form,
                              field: key
                            })}
                          />
                        );
                      } else {
                        elem = (
                          <Dropdown
                            widthM={'100%'}
                            widthT={'35rem'}
                            hasChanged={hasChanged}
                            label={t(key)}
                            value={value}
                            error={serviceSettingsFormErrors[key]}
                            info={fieldItem.info}
                            placeholder={fieldItem.hint}
                            tooltip={fieldItem.tooltip}
                            options={fieldItem.enum.map((o: string) => createDropdownOption(o))}
                            onChange={(option: any) => {
                              setState(prevState => ({
                                ...prevState,
                                form: {
                                  ...prevState.form,
                                  [key]: option.value || ''
                                }
                              }));
                            }}
                            onBlur={() => validateSettingsForm({
                              form: state.form,
                              field: key
                            })}
                          />
                        );
                      }
                    }
                    break;
                  case 'simple-array':
                    if (!isCreateMode && !isEditMode) {
                      if (key === 'fulfillerGroups') {
                        elem = (
                          <Text
                            value={formattedGroupItemsAsList.join(', ')}
                            label={t(key)}
                          />
                        );
                      }
                      else if (key === 'operatingDays') {
                        elem = (
                          <Text
                            value={(value as number[])
                              .map((v, dIndex) => v ? format(setDay(new Date(), dIndex), 'E') : null)
                              .filter(v => v)
                              .join(', ')
                            }
                            label={t(key)}
                          />
                        );
                      } else {
                        elem = (
                          <Text
                            value={value}
                            label={t(key)}
                          />
                        );
                      }
                    } else {
                      if (key === 'fulfillerGroups') {
                        const formGroups: string[] = (valueGroupItems as DropdownOption[]).map(g => g.value);
                        hasChanged = isEditMode && (!formGroups.every((groupId: string) => databaseValue.includes(groupId)) || formGroups.length !== databaseValue.length);

                        elem = (
                          <Dropdown
                            hasChanged={hasChanged}
                            isMulti
                            widthM={'100%'}
                            widthT={'35rem'}
                            label={t(key)}
                            value={valueGroupItems}
                            error={serviceSettingsFormErrors[key]}
                            info={fieldItem.info}
                            placeholder={fieldItem.hint}
                            tooltip={fieldItem.tooltip}
                            options={groupDropdownItems}
                            endAdornment={
                              <IconButton
                                type="button"
                                marginLeft
                                hoverEffect
                                onClick={() => openFulfillerGroupsModal()}
                              >
                                <StyledSettingsIcon />
                              </IconButton>
                            }
                            onChange={(items: any) => {
                              setState(prevState => ({
                                ...prevState,
                                form: {
                                  ...prevState.form,
                                  [key]: items
                                }
                              }));
                            }}
                            onBlur={() => validateSettingsForm({
                              form: state.form,
                              field: key
                            })}
                          />
                        );
                      }
                      else if (key === 'operatingDays') {
                        hasChanged = isEditMode && !isDeepEqual(value, databaseValue);

                        elem = (
                          <AdminFormLine
                            column
                            widthM={'100%'}
                            widthT={'35rem'}
                          >
                            <h5
                              style={{
                                fontSize: '1.2rem',
                                marginBottom: '.5rem'
                              }}
                            >
                              <span>{t(key)}</span>
                              {hasChanged && (
                                <ModifiedCircle leftMargin />
                              )}
                            </h5>
                            <AdminFormLine
                              row
                              centerV
                              spaceBetween
                            >
                              {value.map((day: number, dayIndex: number) => (
                                <Checkbox
                                  key={dayIndex}
                                  label={format(setDay(new Date(), dayIndex), 'E')}
                                  checked={!!day}
                                  labelPos="top"
                                  onChange={(e) => {
                                    const newValue = e.target.checked;

                                    setState(prevState => ({
                                      ...prevState,
                                      form: {
                                        ...prevState.form,
                                        [key]: [
                                          ...(prevState.form[key] as number[]).slice(0, dayIndex),
                                          newValue ? 1 : 0,
                                          ...(prevState.form[key] as number[]).slice(dayIndex + 1)
                                        ]
                                      }
                                    }));
                                  }}
                                />
                              ))}
                            </AdminFormLine>
                          </AdminFormLine>
                        );
                      }
                    }
                    break;
                }

                if (!elem) {
                  return null;
                }

                return (
                  <AdminFormLine key={index} marginBottom>
                    {elem}
                  </AdminFormLine>
                );
              })}
            </ServiceFormWrapper>
          </ConfigDetailsOuter>
        </ConfigDetails>
      </ConfigCard>
    );
  }, [
    t,
    state,
    isCreateMode,
    isEditMode,
    formattedGroupItemsAsList,
    valueGroupItems,
    groupDropdownItems,
    serviceSettingsFormErrors,
    shouldShowSettingsChevron,
    onChangeFormField,
    onChangeFormFieldBoolean,
    openFulfillerGroupsModal,
    validateName,
    onFormSubmit,
    validateSettingsForm,
    operatingHoursValid
  ]);

  const renderBuilderSteps = useCallback(() => {
    if (!(isCreateMode || isEditMode)) {
      return null; 
    }

    return (
      <ConfigCard
        boxShadow
        centerV
        column
      >
        <AdminFormLine>
          <ConfigCardColumn className={'mobile-settings-chevron-button'}>
            <IconButton
              hoverEffect
              type="button"
              onClick={(e: any) => {
                setState(prevState => ({
                  ...prevState,
                  steps: {
                    ...prevState.steps,
                    expanded: !prevState.steps.expanded
                  }
                }));
              }}
            >
              <StyledChevron $down={state.steps.expanded} />
            </IconButton>
          </ConfigCardColumn>
          <ConfigCardColumn>
            <span>Steps</span>
          </ConfigCardColumn>
          <ConfigCardColumn
            style={{
              marginLeft: 'auto'
            }}
          >
            <IconButton
              hoverEffect
              type="button"
              onClick={(e: any) => {
                setState(prevState => ({
                  ...prevState,
                  steps: {
                    ...prevState.steps,
                    showActionMenu: true
                  }
                }));
              }}
            >
              <StyledMenuDots />
              {state.steps.showActionMenu && (
                <Popup
                  id="builder-steps-menu"
                  left
                  bottom
                  convertable
                  onClose={() => {
                    setState(prevState => ({
                      ...prevState,
                      steps: {
                        ...prevState.steps,
                        showActionMenu: false
                      }
                    }));
                  }}
                >
                  {({ closePopup }) => (
                    <MenuWrapper>
                      <MenuItem onClick={() => {
                        closePopup();

                        setState(prevState => ({
                          ...prevState,
                          steps: {
                            ...prevState.steps,
                            createModal: {
                              ...prevState.steps.createModal,
                              show: true
                            }
                          }
                        }));
                      }}>Add step</MenuItem>
                      <MenuItem onClick={() => {
                        closePopup();

                        toggleExpandAllSteps();
                      }}>{`${allStepsCollapsed ? 'Expand' : 'Collapse'} all steps`}</MenuItem>
                      <MenuItem onClick={() => {
                        closePopup();

                        setState(prevState => ({
                          ...prevState,
                          steps: {
                            ...prevState.steps,
                            fieldsAsText: !prevState.steps.fieldsAsText
                          }
                        }));
                      }}>{state.steps.fieldsAsText ? 'Show fields as user input' : 'Show fields as text'}</MenuItem>
                    </MenuWrapper>
                  )}
                </Popup>
              )}
            </IconButton>
          </ConfigCardColumn>
        </AdminFormLine>
        <ConfigDetails isExpanded={state.steps.expanded}>
          <ConfigDetailsOuter>
            {(state.form.steps as any).map((step: Step, index: number) => (
              <AdminFormLine
                marginBottom
                key={step.name}
              >
                <BuilderStep
                  step={step}
                  index={index}
                  isSelected={state.builder.selectedStepIndex === index}
                  isExpanded={state.steps.expandedSteps[step.name]}
                  canUpdateService={canUpdateService(clientId!, userData.user!)}
                  isLast={index === (state.form.steps as any).length - 1}
                  fields={state.fields.data}
                  selectedField={{
                    stepIndex: state.builder.selectedStepIndex,
                    fieldIndex: state.builder.selectedFieldIndex,
                    field: state.builder.field
                  }}
                  showFieldsAsText={state.steps.fieldsAsText}
                  onSelect={selectStep}
                  onExpand={toggleStepExpansion}
                  onRename={(stepToRename: Step) => {
                    setState(prevState => ({
                      ...prevState,
                      steps: {
                        ...prevState.steps,
                        renameModal: {
                          show: true,
                          name: stepToRename.label,
                          index
                        }
                      }
                    }));
                  }}
                  onDelete={(stepIndexToDelete: number) => {
                    deleteStep(stepIndexToDelete);
                  }}
                  onSelectField={selectField}
                  onAddField={addNewField}
                  onEditField={editNewField}
                  onDeleteField={removeField}
                  onReOrder={reOrderSteps}
                  onReOrderField={reOrderFields}
                  onMoveFieldBetweenSteps={changeFieldToNewStep}
                  canDrag={
                    !step.isFixed
                      && !state.steps.expandedSteps[step.name]
                      && state.builder.selectedStepIndex === index
                  }
                />
              </AdminFormLine>
            ))}
          </ConfigDetailsOuter>
        </ConfigDetails>
      </ConfigCard>
    );
  }, [
    isCreateMode,
    isEditMode,
    userData.user,
    clientId,
    state.fields.data,
    state.form.steps,
    state.steps.expanded,
    state.steps.expandedSteps,
    state.steps.showActionMenu,
    state.steps.fieldsAsText,
    state.builder.selectedStepIndex,
    state.builder.selectedFieldIndex,
    state.builder.field,
    allStepsCollapsed,
    reOrderSteps,
    reOrderFields,
    selectStep,
    deleteStep,
    addNewField,
    editNewField,
    selectField,
    removeField,
    toggleStepExpansion,
    changeFieldToNewStep,
    toggleExpandAllSteps
  ]);

  const renderIFrameSection = useCallback(() => {
    return (
      <PreviewPane key={`iframe${isCreateMode || isEditMode ? '-editable' : ''}`}>
        <Device>
          <StyledFrame
            ref={iframeRef}
            title={'Booking preview'}
            onLoad={onFrameLoad}
            src={`${window.location.origin}/${clientId}/booking/${isCreateMode ? 'new' : id}/1`}
          />
          {!state.builder.frameLoaded && (
            <FrameSpinner>
              <Spinner
                color={theme.textColor}
                size={'L'}
              />
            </FrameSpinner>
          )}
        </Device>
      </PreviewPane>
    );
  }, [
    isCreateMode,
    isEditMode,
    clientId,
    id,
    state.builder.frameLoaded,
    onFrameLoad
  ]);

  const renderForm = useCallback(() => {
    if (state.service.loading || (isCreateMode && state.defaultServiceSteps.loading)) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (state.service.error || (isCreateMode && state.defaultServiceSteps.error)) {
      return null;
    }

    if (!state.fields.data || (isCreateMode && !state.defaultServiceSteps.data)) {
      return null;
    }

    // 110 is header(60px) + top padding of layout InnerWrapper(50px)

    return (
      <CreateServiceFormWrapper
        noOverflow
        onSubmit={onFormSubmit}
      >
        <Grid>
          <MobileSettingsWrapper show={state.showMobileSettings || state.showMobileFieldOptions}>
            <ConfigWrapper>
              {isMobile ? state.showMobileFieldOptions ? renderBuilderSteps() : null : renderBuilderSteps()}
              {isMobile ? state.showMobileFieldOptions ? null : renderSettings() : renderSettings()}
            </ConfigWrapper>
            {isMobile && (state.showMobileSettings || state.showMobileFieldOptions) && (
              <IconButton
                medium
                hoverEffectAlwaysOn
                style={{
                  position: 'fixed',
                  top: '5rem',
                  right: 0,
                  backgroundColor: 'white'
                }}
                onClick={() => {
                  setState(prevState => ({
                    ...prevState,
                    showMobileSettings: false,
                    showMobileFieldOptions: false
                  }));
                }}
              >
                <StyledCross />
              </IconButton>
            )}
          </MobileSettingsWrapper>
          <AdminFormLine className={'mobile-settings-seperator'}>
            <StyledHR $alt />
          </AdminFormLine>
          <IFrameSectionWrapper
            blur={state.showMobileSettings || state.showMobileFieldOptions}
          >
            {renderIFrameSection()}
            {(isCreateMode || isEditMode) && createPortal(
              <MobileSettingIconWrapper mobileSettingsOn={state.showMobileSettings || state.showMobileFieldOptions}>
                <IconButton
                  medium
                  hoverEffectAlwaysOn
                  style={{
                    backgroundColor: 'white'
                  }}
                  onClick={() => {
                    onCancel();
                  }}
                >
                  <StyledMobileChevronIcon />
                </IconButton>
                <IconButton
                  medium
                  hoverEffectAlwaysOn
                  style={{
                    backgroundColor: 'white'
                  }}
                  onClick={() => {
                    setState(prevState => ({
                      ...prevState,
                      showMobileSettings: true
                    }));
                  }}
                >
                  <StyledMobileSettingsIcon />
                </IconButton>
                <IconButton
                  medium
                  hoverEffectAlwaysOn
                  type="button"
                  style={{
                    backgroundColor: 'white'
                  }}
                  onClick={() => {
                    setState(prevState => ({
                      ...prevState,
                      showMobileFieldOptions: true
                    }));
                  }}
                >
                  <StyledMobileEditIcon />
                </IconButton>
                <IconButton
                  medium
                  hoverEffectAlwaysOn
                  style={{
                    backgroundColor: 'white'
                  }}
                  onClick={() => {
                    if (isSaveButtonDisabled) {
                      return;
                    }

                    onFormSubmit(window.event);
                  }}
                >
                  {state.serviceCreate.loading || state.serviceUpdate.loading ? (
                    <Spinner
                      color={theme.textColor}
                      size={'S'}
                    />
                  ) : (
                    <StyledMobileTickIcon disabled={isSaveButtonDisabled} />
                  )}
                </IconButton>
              </MobileSettingIconWrapper>,
              document.body
            )}
          </IFrameSectionWrapper>
        </Grid>

        <AdminFormLine className={'mobile-actions-seperator'}>
          <HR $alt />
        </AdminFormLine>

        {!isCreateMode && !isEditMode && (
          <AdminFormLine
            right
            topPadding
          >
            {canReadPricing(clientId!, userData.user!) && (
              <Button
                type={'button'}
                onClick={onManagePricingRules}
              >Manage pricing rules</Button>
            )}
          </AdminFormLine>
        )}

        {(isCreateMode || isEditMode) && (
          <AdminFormLine
            right
            topPadding
            spaceBetween
            className={'mobile-page-actions'}
          >
            <Button
              type={'button'}
              onClick={onCancel}
            >Cancel</Button>
            <AdminFormLine>
              {canReadPricing(clientId!, userData.user!) && (
                <Button
                  type={'button'}
                  style={{ marginRight: '1rem' }}
                  disabled={isCreateMode}
                  onClick={onManagePricingRules}
                >Manage pricing rules</Button>
              )}
              {(canCreateService(clientId!, userData.user!) || canUpdateService(clientId!, userData.user!)) && (
                <PrimaryButton
                  type={'submit'}
                  loading={state.serviceCreate.loading || state.serviceUpdate.loading}
                  disabled={isSaveButtonDisabled}
                  spinnerColor={theme.colors.coreSecondary}
                >{`${isCreateMode ? 'Create' : isEditMode ? 'Update' : ''} service`}</PrimaryButton>
              )}
            </AdminFormLine>
          </AdminFormLine>
        )}
      </CreateServiceFormWrapper>
    );
  }, [
    state,
    clientId,
    userData.user,
    isCreateMode,
    isEditMode,
    isMobile,
    onFormSubmit,
    onManagePricingRules,
    onCancel,
    renderSettings,
    renderBuilderSteps,
    renderIFrameSection,
    isSaveButtonDisabled
  ]);

  const renderFulfillerGroupsModal = useCallback(() => {
    if (!state.fulfillerGroups.modalShown || !state.fulfillerGroupsRepo.data) {
      return null;
    }

    const fulfillerGroups: string[] = ((state.form.fulfillerGroups as Array<string | DropdownOption>) || []).map((g: string | DropdownOption) => (g as DropdownOption).value ? (g as DropdownOption).value : g);

    const combinedFulfillers: Fulfiller[] = [
      ...state.fulfillerGroupsRepo.data!.reduce((acc: Fulfiller[], curr: FulfillerGroup) => {
        if (fulfillerGroups.includes(curr._id)) {
          curr.fulfillers.forEach((fulfiller: Fulfiller) => { 
            if (!acc.find(f => f._id === fulfiller._id)) {
              acc.push(fulfiller);
            }
          });
        }

        return acc;
      }, [])
    ];

    if ((state.service.data && state.service.data.fulfillerAssignmentProcess === FulfillerAssignmentProcess.Cycle) || isCreateMode) {
      combinedFulfillers.sort((a, b) => a.created.at.localeCompare(b.created.at));
    }

    const groupMap = state.fulfillerGroupsRepo.data.reduce((acc: any, curr: FulfillerGroup) => {
      if (!acc[curr._id]) {
        acc[curr._id] = curr;
      }

      return acc;
    }, {});

    return (
      <Popup
        id={'fulfiller-groups-modal'}
        layered
        convertable
        noPadding
        onClose={closeFulfillerGroupsModal}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0}}>Fulfiller groups</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine marginBottom>
                  <Dropdown
                    isMulti
                    widthM={'100%'}
                    widthT={'35rem'}
                    label={'Selected groups'}
                    value={valueGroupItems}
                    options={groupDropdownItems}
                    onChange={(items: any) => {
                      setState(prevState => ({
                        ...prevState,
                        form: {
                          ...prevState.form,
                          fulfillerGroups: items
                        }
                      }));
                    }}
                  />
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalContentWrapper>
                <ModalTable
                  namespaceKey={'fulfillers'}
                  headerConfig={[
                    {
                      key: 'name',
                      label: 'Name',
                      width: '20%',
                      type: CellType.Text,
                      sortable: true,
                      format: (_, row) => `${row.firstName} ${row.lastName}`
                    },
                    {
                      key: 'email',
                      label: 'Email',
                      width: '20%',
                      type: CellType.Text,
                      sortable: true
                    },
                    {
                      key: 'fulfillerGroups',
                      label: 'Groups',
                      width: '20%',
                      type: CellType.Text,
                      sortable: true,
                      format: (groupIds: string[]) => {
                        return (
                          <b>
                            {groupIds
                              .map(groupId => groupMap[groupId] ? groupMap[groupId].name : `Unkown group: ${groupId}`)
                              .join(', ')}
                          </b>
                        );
                      }
                    },
                    {
                      key: 'created',
                      label: 'Joined',
                      width: '20%',
                      type: CellType.Text,
                      format: (created) => {
                        return format(new Date(created.at), 'dd/MM/yyyy')
                      }
                    }
                  ]}
                  rows={combinedFulfillers}
                  total={combinedFulfillers.length}
                />
              </ModalContentWrapper>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine right>
                  <Button
                    type={'button'}
                    onClick={() => {
                      closePopup();
                    }}
                  >Close</Button>
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    isCreateMode,
    valueGroupItems,
    groupDropdownItems,
    state.fulfillerGroupsRepo.data,
    state.service.data,
    state.form.fulfillerGroups,
    state.fulfillerGroups.modalShown,
    closeFulfillerGroupsModal
  ]);

  const onEdit = useCallback(() => {
    navigate({ pathname: `./edit` });
  }, [navigate]);

  const removeMessageListener = useCallback(() => {
    if (iframeRef.current) {
      iframeRef.current!.contentWindow!.removeEventListener('message', onIframeMessage);
    }
  }, [onIframeMessage]);

  const [throttleIframeMessage] = useDebouncedCallback((frameData: any) => {
    if (iframeRef.current) {
      iframeRef.current!.contentWindow!.postMessage(JSON.stringify(frameData), window.location.origin);
    }
  }, 50);

  // TODO: to reset the form pass in Actions.RESET_FORM
  useEffect(() => {
    const frameData = {
      action: FrameActions.UPDATE_CHILD,
      to: 'child',
      payload: {
        service: {
          data: {
            ...state.service.data,
            ...state.form,
            ...(isCreateMode && {
              _id: 'newService',
              clientId
            })
          },
          // groupedFields,
          // TODO: use initialState from state.ts
          builder: {
            context: 'service',
            selectedStepIndex: state.builder.selectedStepIndex,
            selectedFieldIndex: state.builder.selectedFieldIndex,
            selectedFields: [],
            field: state.builder.field,
            isSelectable: true,
            isEditable: isMobile,
            isSortable: true,
            isDeletable: true,
            hideBackground: isMobile,
            hideFooter: isMobile
          }
        }
      }
    };

    if (iframeRef.current) {
      iframeRef.current!.contentWindow!.postMessage(JSON.stringify(frameData), window.location.origin);
    }
    // console.log('--------in update');
  }, [
    isCreateMode,
    isMobile,
    state,
    clientId
  ]);

  useEffect(() => {
    if (!state.fields.data && !state.fields.error) {
      fetchFields();
    }

    if (isCreateMode) {
      if (!state.defaultServiceSteps.data && !state.defaultServiceSteps.error) {
        fetchDefaultServiceSteps();
      }

      if (!state.client.data && !state.client.error) {
        fetchClient();
      }
    } else {
      if (!state.service.data && !state.service.error) {
        fetchService();
      }
    }
  }, [
    isCreateMode,
    state.form,
    state.fields,
    fetchFields,
    state.service,
    fetchService,
    state.defaultServiceSteps,
    fetchDefaultServiceSteps,
    state.client,
    fetchClient,
  ]);

  useEffect(() => {
    if (!state.fulfillerGroupsRepo.data && !state.fulfillerGroupsRepo.error) {
      fetchFulfillerGroups();
    }
  }, [
    state.fulfillerGroupsRepo.data,
    state.fulfillerGroupsRepo.error,
    fetchFulfillerGroups
  ]);

  useEffect(() => {
    return () => {
      removeMessageListener();
    };
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        expanded: !shouldShowSettingsChevron
      }
    }));
  }, [shouldShowSettingsChevron]);

  // console.log('--------------------------form', state.form);

  return (
    <Wrapper>
      <PageHeader
        title={`${isCreateMode ? 'Create service' : isEditMode ? 'Edit service' : 'Service'}`}
        rightContent={!isCreateMode && state.service.data && (
          <>
            {(canUpdateService(clientId!, userData.user!)) && (
              <Button
                style={{marginBottom: 0}}
                icon={<Edit />}
                onClick={onEdit}
                disabled={isEditMode}
              >Edit</Button>
            )}
          </>
        )}
        tag={isCreateMode || isEditMode ? 'mobile-page-header' : undefined}
      />
      {/* Add as a blurred modal actioned by a question mark */}
      {false && (
        <>
          <AdminFormLine marginBottom>A service is a set of form fields that describe the work needed to be carried out by the fulfiller. These are the fields that an end customer or an admin sees when creating a job. Jobs can be created based on Services.</AdminFormLine>
          <AdminFormLine marginBottom />
        </>
      )}
      {!isCreateMode && !isEditMode && state.service.data && (
        <AdminFormLine marginBottom>
          <StyledStatusIndicator
            isEnabled={state.service.data.isEnabled}
            title={state.service.data.isEnabled ? 'Enabled' : 'Disabled'}
          />
          <StyledLinkExternal
            bold
            inline
            target={'_blank'}
            href={getServiceLink(clientId!, id!)}
          >{state.service.data && state.service.data.name}</StyledLinkExternal>
        </AdminFormLine>
      )}
      {renderForm()}
      {renderFulfillerGroupsModal()}
      <StepNameModal
        key={`${state.steps.createModal.show.toString()}-${state.steps.renameModal.show.toString()}`}
        show={state.steps.createModal.show || state.steps.renameModal.show}
        stepNames={((state.form.steps as any) || []).map((step: Step) => step.label)}
        name={state.steps.renameModal.name || undefined}
        index={state.steps.renameModal.index >= 0 ? state.steps.renameModal.index : -1}
        onClose={closeNameModal}
        onSubmit={upsertStep}
      />
    </Wrapper>
  );
};

export default memo(Service);

