import React from 'react'

import { Spinner } from 'components/atoms';

import { StyledButton, ContentWrapper } from './Button.styles'
import { StyledPrimaryButton } from './PrimaryButton.styles'
import { FormFieldProps } from '../form/FormField';

export interface ButtonProps {
  width?: string;
  icon?: React.ReactElement;
  loading?: boolean;
  spinnerColor?: string;
  noPadding?: boolean;
  badge?: React.ReactElement;
}

enum Type {
  Default,
  Primary
}

type StyledButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & ButtonProps & Pick<FormFieldProps, 'noMinWidth'>;

const getComponent = (ref: React.ForwardedRef<HTMLButtonElement>, type: Type, props: StyledButtonProps) => {
  const {
    width,
    icon,
    loading,
    spinnerColor,
    disabled,
    noMinWidth,
    children,
    badge,
    ...rest
  } = props;

  let Elem = null;

  switch (type) {
    case Type.Default:
      Elem = StyledButton;
      break;
    case Type.Primary:
      Elem = StyledPrimaryButton;
      break;
  }

  return (
    <Elem
      {...rest}
      ref={ref}
      $width={width}
      $loading={loading}
      disabled={disabled || loading}
      iconOnly={!!((!children) && icon)}
    >
      {icon ? (
        <ContentWrapper>
          {loading ? (
            <Spinner color={spinnerColor} /> 
          ) : (
            <>
              {icon}
              {children && (
                <span>{children}</span>
              )}
              {badge && badge}
            </>
          )}

        </ContentWrapper>
      ) : (
        loading ? (
          <Spinner color={spinnerColor}/> 
        ) : (
          <>
            {children && (
              <span>{children}</span>
            )}
            {badge && badge}
          </>
        )
      )}
    </Elem>
  );
};

// Add use forwardRef to allow attaching ref to underlying button element
export const Button = React.forwardRef<HTMLButtonElement, StyledButtonProps>((props, ref) => {
  return getComponent(ref, Type.Default, props);
});

// Add use forwardRef to allow attaching ref to underlying button element
export const PrimaryButton = React.forwardRef<HTMLButtonElement, StyledButtonProps>((props, ref) => {
  return getComponent(ref, Type.Primary, props);
});

