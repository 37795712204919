import React, { useState } from 'react';
// tslint:disable-next-line
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Props as SelectProps } from 'react-select';

import { FormField } from '../form';
import { BaseProps } from '../form/types';
import { FormFieldProps } from '../form/FormField';

import { selectStyles } from './CreatableDropdown.styles';
import { getTheme } from './Dropdown.styles';

export interface AsyncCreatableDropdownProps extends SelectProps {
  loadOptions: (value: string) => Promise<Array<{ label: string; value: string }>>;
  isClearable?: boolean;
}

export type Props = AsyncCreatableDropdownProps & BaseProps & FormFieldProps;

const AsyncCreatableDropdown = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    label,
    value,
    error,
    hasChanged,
    width,
    widthM,
    widthT,
    widthL,
    isClearable,
    options,
    noWidth,
    noMinWidth,
    onFocus,
    onBlur,
    onChange,
    ...rest
  } = props;

  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <FormField
      id={rest.id}
      error={error}
      label={label}
      className={className}
      style={rest.styles}
      hasChanged={hasChanged}
      value={value}
      width={width}
      widthM={widthM}
      widthT={widthT}
      widthL={widthL}
      noWidth={noWidth}
      noMinWidth={noMinWidth}
      isFocused={isFocused}
      info={rest.info}
      tooltip={rest.tooltip}
      builder={rest.builder}
      endAdornment={rest.endAdornment}
      formFieldStyles={rest.formFieldStyles}
    >
      <AsyncCreatableSelect
        {...props}
        defaultOptions
        components={{DropdownIndicator: null}}
        value={value}
        styles={selectStyles(isFocused, !!error)}
        theme={(theme) => getTheme(theme)}
        menuPortalTarget={document.body as HTMLElement}
        menuPosition={'fixed'}
        menuShouldScrollIntoView={false}
        closeMenuOnScroll={e => {
          if (e.target && ((e.target as HTMLElement).className || '').includes('MenuList')) {
            return false;
          }

          return true;
        }}
        options={options}
        onChange={(newValue, actionMeta) => {
          if (onChange) {
            onChange(newValue, actionMeta);
          }
        }}
        onFocus={e => {
          setIsFocused(true);

          if (onFocus) {
            onFocus(e as any);
          }
        }}
        onBlur={e => {
          setIsFocused(false);

          if (onBlur) {
            onBlur(e as any);
          }
        }}
      />
    </FormField>
  );
});

export default AsyncCreatableDropdown;

