import { Dispatch } from 'react';

import { Client } from 'types/Client';
import { User, UserData } from 'types/User';
import { Service, Builder } from 'types/Service';
import { Job } from 'types/Job';
import { BrowserWindow } from 'types/UI';
import {
  LocalError,
  NetworkError,
  ApplicationError
} from 'types/Error';
import { LOCAL_STORAGE_KEY } from './constants';

import {
  isInIframe,
  isInBookingPage,
  isInBackofficePage
} from 'utils/general';

export enum Actions {
  LOAD_DEVICE_DATA = 'LOAD_DEVICE_DATA',
  REMOVE_DEVICE_DATA = 'REMOVE_DEVICE_DATA',

  UPDATE_USER_CACHE = 'UPDATE_USER_CACHE',
  LOADING_CLIENT = 'LOADING_CLIENT',
  CACHE_CLIENT = 'CACHE_CLIENT',
  ERROR_CLIENT = 'ERROR_CLIENT',

  LOADING_SERVICE = 'LOADING_SERVICE',
  CACHE_SERVICE = 'CACHE_SERVICE',
  ERROR_SERVICE = 'ERROR_SERVICE',

  LOADING_SERVICES = 'LOADING_SERVICES',
  CACHE_SERVICES = 'CACHE_SERVICES',
  ERROR_SERVICES = 'ERROR_SERVICES',


  LOADING_JOB = 'LOADING_JOB',
  SAVE_JOB_FORM = 'SAVE_JOB_FORM',
  SAVE_JOB = 'SAVE_JOB',
  RESET_JOB = 'RESET_JOB',
  RESET_FORM = 'RESET_FORM',
  ERROR_JOB = 'ERROR_JOB',

  JOB_TO_COPY = 'JOB_TO_COPY',
  RESET_JOB_TO_COPY = 'RESET_JOB_TO_COPY'
}

export enum FrameActions {
  UPDATE_CHILD = 'UPDATE_CHILD',
  SELECT_STEP = 'SELECT_STEP',
  SELECT_FIELD = 'SELECT_FIELD',
  EDIT_FIELD = 'EDIT_FIELD',
  DELETE_FIELD = 'DELETE_FIELD',
  REORDER_FIELDS = 'REORDER_FIELDS',
  REMOVE_STEP = 'REMOVE_STEP',
  PRICE_RESULTS = 'PRICE_RESULTS',
  ENABLE_FIELD_PRICING = 'ENABLE_FIELD_PRICING',
  HIDE_BACKGROUND_AND_FOOTER = 'HIDE_BACKGROUND_AND_FOOTER'
};

export interface State {
  client: {
    loading: boolean;
    data: Client | null;
    error: NetworkError | null;
  };
  service: {
    loading: boolean;
    data: Service | null;
    builder: Builder;
    error: ApplicationError | null;
  };
  services: {
    loading: boolean;
    data: Service[] | null;
    error: NetworkError | null;
  };
  job: {
    loading: boolean;
    form: any;
    data: Job | null;
    error: NetworkError | null;
  };
  admin: {
    service: {
      fields: any;
    };
  };
  userData: UserData;
  jobToCopy: {
    form: any | null;
  };
}

export type Action =
  | { type: Actions.LOAD_DEVICE_DATA; payload: State['job']['form']; }
  | { type: Actions.REMOVE_DEVICE_DATA; payload?: {}; }
  | { type: Actions.UPDATE_USER_CACHE; payload: State['userData']; }
  | { type: Actions.LOADING_CLIENT, payload?: {} }
  | { type: Actions.CACHE_CLIENT, payload: Client; }
  | { type: Actions.ERROR_CLIENT, payload: NetworkError; }
  | { type: Actions.CACHE_SERVICE, payload: Pick<State['service'], 'data'>; }
  | { type: Actions.LOADING_SERVICES, payload?: {}; }
  | { type: Actions.LOADING_SERVICE, payload?: {}; }
  | { type: Actions.CACHE_SERVICES, payload: Service[]; }
  | { type: Actions.ERROR_SERVICES, payload: NetworkError; }
  | { type: Actions.ERROR_SERVICE, payload: NetworkError; }
  | { type: Actions.LOADING_JOB, payload?: {}; }
  | { type: Actions.SAVE_JOB_FORM, payload: { step: string; fields: any; } }
  | { type: Actions.SAVE_JOB, payload: State['job']; }
  | { type: Actions.RESET_JOB, payload?: {}; }
  | { type: Actions.RESET_FORM, payload: string; }
  | { type: Actions.ERROR_JOB, payload: NetworkError; }
  | { type: Actions.JOB_TO_COPY, payload: Partial<State['job']['data']>; }
  | { type: Actions.RESET_JOB_TO_COPY, payload?: {}; }
  | { type: FrameActions.UPDATE_CHILD, payload: State['service']; }
  | { type: FrameActions.HIDE_BACKGROUND_AND_FOOTER, payload: State['service']; }

export const initialState: State = {
  client: {
    loading: false,
    data: null,
    error: null
  },
  service: {
    loading: false,
    data: null,
    builder: {
      context: '',
      parentReady: isInIframe(window) ? false : null,
      selectedStepIndex: -1,
      selectedFieldIndex: -1,
      selectedFields: [],
      field: null,
      selectedPrice: null,
      isSelectable: true,
      isEditable: true,
      isSortable: true,
      isDeletable: true,
      isMultiple: false,
      testMode: false,
      hideBackground: false,
      hideFooter: false
    },
    error: null
  },
  services: {
    loading: false,
    data: null,
    error: null
  },
  job: {
    loading: false,
    form: {},
    data: null,
    error: null
  },
  admin: {
    service: {
      fields: null
    }
  },
  userData: {
    loading: false,
    fields: {},
    user: null,
    errors: null
  },
  jobToCopy: {
    form: null
  }
};

export const reducer = (state: State, action: Action): State => {
  // console.log('---------reducer action', action);

  const payload: any = action.payload;
  const fields: any = payload ? payload.fields : {};

  switch (action.type) {
    case Actions.LOAD_DEVICE_DATA: {
      return {
        ...state,
        job: {
          ...state.job,
          form: {
            ...payload
          }
        }
      }
    }
    case Actions.REMOVE_DEVICE_DATA: {
      return {
        ...state,
        job: {
          ...state.job,
          form: initialState.job.form
        }
      }
    }
    case Actions.UPDATE_USER_CACHE:
      const loading: boolean = payload.loading;
      const user: User = payload.user;
      const errors: ApplicationError[] | null = payload.errors;

      return {
        ...state,
        userData: {
          loading,
          user,
          fields,
          errors
        }
      }
    case Actions.LOADING_CLIENT:
      return {
        ...state,
        client: {
          ...state.client,
          loading: true
        }
      }
    case Actions.CACHE_CLIENT:
      return {
        ...state,
        client: {
          loading: false,
          data: action.payload,
          error: null
        }
      }
    case Actions.ERROR_CLIENT:
      return {
        ...state,
        client: {
          loading: false,
          data: null,
          error: action.payload
        }
      }
    case Actions.CACHE_SERVICE:
      return {
        ...state,
        service: {
          ...state.service,
          data: action.payload.data
        }
      }
    case Actions.LOADING_SERVICE:
      return {
        ...state,
        service: {
          ...state.service,
          loading: true
        }
      }
    case Actions.LOADING_SERVICES:
      return {
        ...state,
        services: {
          ...state.services,
          loading: true
        }
      }
    case Actions.CACHE_SERVICES:
      return {
        ...state,
        services: {
          loading: false,
          data: action.payload,
          error: null
        }
      }
    case Actions.ERROR_SERVICES:
      return {
        ...state,
        services: {
          loading: false,
          data: null,
          error: action.payload
        }
      }
    case Actions.ERROR_SERVICE:
      return {
        ...state,
        service: {
          ...state.service,
          loading: false,
          data: null,
          error: action.payload
        }
      }
    case Actions.LOADING_JOB: {
      return {
        ...state,
        job: {
          ...state.job,
          loading: true
        }
      }
    }
    case Actions.SAVE_JOB_FORM: {
      const step: string = payload.step;

      return {
        ...state,
        job: {
          ...state.job,
          form: {
            ...state.job.form,
            [step]: {
              ...state.job.form[step],
              ...fields
            }
          }
        }
      }
    }
    case Actions.SAVE_JOB: {
      const jobFields: any = payload.fields;

      return {
        ...state,
        job: {
          loading: false,
          form: {
            ...jobFields
          },
          data: {
            ...payload
          },
          error: null
        }
      }
    }
    case Actions.RESET_JOB:
      return {
        ...state,
        job: {
          ...initialState.job
        }
      }
    case Actions.RESET_FORM:
      const serviceId: string = payload;

      removeDeviceData(serviceId);

      return {
        ...state,
        job: {
          ...initialState.job
        }
      }
    case Actions.ERROR_JOB:
      return {
        ...state,
        job: {
          ...state.job,
          loading: false,
          data: null,
          error: action.payload
        }
      }
    case Actions.JOB_TO_COPY: {
      return {
        ...state,
        jobToCopy: {
          ...state.jobToCopy,
          form: payload
        }
      }
    }
    case Actions.RESET_JOB_TO_COPY: {
      return {
        ...state,
        jobToCopy: {
          ...initialState.jobToCopy
        }
      }
    }

    // frame actions
    case FrameActions.UPDATE_CHILD: {
      const service: any = payload.service;

      return {
        ...state,
        service: {
          ...state.service,
          ...service
        }
      }
    }
    case FrameActions.HIDE_BACKGROUND_AND_FOOTER: {
      const service: any = payload.service;

      return {
        ...state,
        service: {
          ...state.service,
          ...service,
          builder: {
            ...state.service.builder,
            ...service.builder
          }
        }
      }
    }
    default:
      throw new LocalError(undefined, undefined, { errorCode: '1201' });
  }
};

const getDeviceData = (): any => {
  let data: any | null  = {};

  if (!isInBackofficePage(window)) {
    data = localStorage.getItem(LOCAL_STORAGE_KEY);
  }

  // TODO: check for erroneous data
  try {
    data = JSON.parse(data);
  } catch (e) {
    // TODO: log error (malformed data)
  }

  return data;
};

export const loadDeviceData = (state: State, dispatch: Dispatch<Action>) => {
  if (!(isInBookingPage(window) && !isInIframe(window) && !(window as BrowserWindow).isModifiable)) {
    return;
  }

  if (state.service.data && state.service.data._id) {
    const deviceData = getDeviceData();

    if (
      deviceData
      && !state.job.data
      && Object.keys(state.job.form).length === 0
      && deviceData[state.service.data._id]
      && Object.keys(deviceData[state.service.data._id]).length > 0
    ) {
      dispatch({
        type: Actions.LOAD_DEVICE_DATA,
        payload: deviceData[state.service.data._id]
      });
    }
  }
};

export const removeDeviceData = (serviceId: string) => {
  const currentDeviceData = getDeviceData();

  if (currentDeviceData && currentDeviceData[serviceId]) {
    delete currentDeviceData[serviceId];

    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(currentDeviceData)
    );

    const deviceDataPostDelete = getDeviceData();

    if (deviceDataPostDelete && Object.keys(deviceDataPostDelete).length === 0) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }
};

export const saveFormData = (state: State) => {
  if (!(isInBookingPage(window) && !isInIframe(window) && !(window as BrowserWindow).isModifiable)) {
    return;
  }

  if (
    state.job.data
    || !state.service.data
    || Object.keys(state.job.form).length === 0
  ) {
    return;
  }

  const currentDeviceData = getDeviceData();

  localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify({
      ...(currentDeviceData && {
        ...currentDeviceData
      }),
      [state.service.data._id]: state.job.form
    })
  );
};

