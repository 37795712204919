import React, { FC, memo, useCallback, useRef, useEffect, useState } from 'react';

import {
  FormLine,
  HR
} from 'theme/mixins';

import {
  Wrapper,
  Steps,
  Step,
  StepOuter,
  StepInner,
  StepInfo,
  StyledTickWrapper,
  StyledTick,
  FloatingLabel,
  EditWrapper,
  StyledIconWrapper,
  StyledCross
} from './Progress.styles';

export interface ProgressStep {
  name: string;
  label: string;
  completed?: boolean;
  link?: string;
  isModifiable?: boolean;
}

interface ProgressProps {
  activeStep: number;
  steps: ProgressStep[];
  floatingLabel?: string | React.ReactNode;
  errorSteps?: string[];
  onCurrentStepWasPreviouslyTruncated: (currentStep: ProgressStep) => void;
  onStepClick?: (
    e: any,
    opts: {
      linkConfig: {
        link: string | null;
        search: string | null;
      },
      step: ProgressStep;
    }
  ) => void;
  onRemoveStep?: (step: ProgressStep) => void;
}

const stacked = false;

const Progress: FC<ProgressProps> = props => {
  const {
    activeStep,
    steps,
    floatingLabel,
    errorSteps,
    onCurrentStepWasPreviouslyTruncated,
    onStepClick,
    onRemoveStep
  } = props;

  const stepsRef = useRef<HTMLDivElement>(null);
  const [stepWidth, setStepWidth] = useState<number>(0);
  const [stackedLineWidth, setStackedLineWidth] = useState<number>(0);
  const [prevTruncatedSteps, setPrevTruncatedSteps] = useState<ProgressStep[]>([]);

  // console.log('---steps', steps);

  let truncatedSteps: ProgressStep[] = steps;
  let truncatedActiveStep: number = activeStep;
  const isTruncated: boolean = steps.length > 4;

  if (isTruncated) {
    const firstStep: number = activeStep < steps.length - 4 ? activeStep : steps.length - 4;

    // console.log('----------firstStep', firstStep, activeStep);

    truncatedSteps = [
      { ...truncatedSteps[firstStep] },
      { ...truncatedSteps[steps.length - 3] },
      { ...truncatedSteps[steps.length - 2] },
      { ...truncatedSteps[steps.length - 1] }
    ];

    truncatedActiveStep = activeStep < steps.length - 4 ? 0 : activeStep - (steps.length - 4);
  }

  // console.log('----------truncatedSteps', activeStep, truncatedSteps);

  const isStepCompleted = useCallback((
    index: number,
    completed: boolean | undefined,
    truncated: boolean,
    trunActiveStep: number
  ): boolean => {
    if (completed !== undefined) {
      return completed;
    } else {
      if (truncated) {
        if (index < trunActiveStep) {
          return true;
        }
      } else {
        if (index < activeStep) {
          return true;
        }
      }
    }

    return false;
  }, [activeStep]);

  const getStepWidth = useCallback(() => {
    if (stepsRef.current) {
      const stepElem: HTMLDivElement = stepsRef.current.children[0] as HTMLDivElement;

      if (stepElem) {
        setStepWidth(stepElem.offsetWidth);
      }
    }
  }, []);

  const getStackedLineWidth = useCallback(() => {
    if (stepsRef.current) {
      const nextUpIndex: number = activeStep + 1 === steps.length - 1 ? -1 : activeStep + 1;

      if (nextUpIndex === -1) {
        // console.log('------------------------nextUp is -1');
        return 0;
      }

      const activeStepElem: HTMLDivElement = stepsRef.current.children[activeStep] as HTMLDivElement;
      const stepElem: HTMLDivElement = stepsRef.current.children[nextUpIndex] as HTMLDivElement;

      if (stepElem) {
        const marginLeft: number = Number(window.getComputedStyle(stepElem).marginLeft.replace('px', ''));
        const offset: number = 25;
        const width: number = activeStepElem.offsetWidth * .5 + marginLeft - offset;

        setStackedLineWidth(width);
      }
    }
  }, [
    activeStep,
    steps.length
  ]);

  const onResize = useCallback(() => {
    getStepWidth();
    getStackedLineWidth();
  }, [
    getStepWidth,
    getStackedLineWidth
  ]);

  const attachResizeListener = useCallback(() => {
    window.addEventListener('resize', onResize);
  }, [onResize]);

  const detachResizeListener = useCallback(() => {
    window.removeEventListener('resize', onResize);
  }, [onResize]);

  useEffect(() => {
    attachResizeListener();
  }, [attachResizeListener]);

  useEffect(() => {
    return () => {
      // console.log('-in unload: Progress');
      detachResizeListener();
    }
  }, [detachResizeListener]);

  useEffect(() => {
    if (stacked) {
      getStackedLineWidth();
    }
  }, [getStackedLineWidth]);

  useEffect(() => {
    getStepWidth();
    getStackedLineWidth();
  }, [
    getStepWidth,
    getStackedLineWidth
  ]);

  useEffect(() => {
    const prevTruncatedStepNames: string[] = prevTruncatedSteps.map(s => s.name);
    const currTruncatedStepNames: string[] = truncatedSteps.map(s => s.name);

    const prevActiveStepName: string = prevTruncatedSteps.length ? prevTruncatedSteps[truncatedActiveStep].name : '';
    const currActiveStep: ProgressStep = truncatedSteps[truncatedActiveStep];
    const currActiveStepName: string = currActiveStep.name;

    if (prevTruncatedSteps.length && prevActiveStepName !== currActiveStepName) {
      onCurrentStepWasPreviouslyTruncated(currActiveStep);
    }

    if (prevTruncatedStepNames.join(',') !== currTruncatedStepNames.join(',')) {
      setPrevTruncatedSteps(truncatedSteps);
    }
  }, [
    prevTruncatedSteps,
    truncatedSteps,
    truncatedActiveStep,
    onCurrentStepWasPreviouslyTruncated
  ]);

  return (
    <Wrapper>
      <Steps
        ref={stepsRef}
        stacked={stacked}
      >
        {truncatedSteps.map((step, index: number) => {
          const active: boolean = steps[activeStep].name === step.name;
          const stepCompleted: boolean = isStepCompleted(
            index,
            step.completed,
            isTruncated,
            truncatedActiveStep
          );
          const linkSplit: string[] | null = step.link ? step.link.split('?') : null;
          const link: string | null = linkSplit ? linkSplit[0] : null;
          const search: string | null = linkSplit ? linkSplit[1] : null;
          const originalIndex: number = steps.findIndex(s => s.name === step.name);
          const hasError: boolean = !!errorSteps?.includes(step.name);

          // console.log('--------------------step', step.name, active, stepCompleted, truncatedActiveStep);

          return (
            <Step
              stacked={stacked}
              key={originalIndex}
              $active={active}
              nextUp={activeStep + 1 === index}
              isLast={truncatedSteps.length - 1 === index}
              completed={stepCompleted}
              stackedLineWidth={stackedLineWidth}
              style={{ zIndex: truncatedSteps.length - index }}
            >
              {step.isModifiable && onRemoveStep && (
                <EditWrapper>
                  <StyledIconWrapper right onClick={() => onRemoveStep(step)}>
                    <StyledCross />
                  </StyledIconWrapper>
                </EditWrapper>
              )}
              <StepOuter>
                <StepInner
                  $active={active}
                  $completed={stepCompleted}
                  $isTruncated={isTruncated && index === 0}
                  to={{
                    pathname: link,
                    search
                  }}
                  replace
                  $hasError={hasError}
                  onClick={(e) => {
                    if (!step.link) {
                      e.preventDefault();

                      return;
                    }

                    if (onStepClick) {
                      onStepClick(
                        e,
                        {
                          linkConfig: {
                            link, search
                          },
                          step
                        }
                      );
                    }
                  }}
                >
                  <span>{originalIndex + 1}</span>
                  {stepCompleted && !hasError && (
                    <StyledTickWrapper>
                      <StyledTick />
                    </StyledTickWrapper>
                  )}
                </StepInner>
                <StepInfo
                  $active={active}
                  $completed={stepCompleted}
                  to={{
                    pathname: link,
                    search
                  }}
                  replace
                  $hasError={hasError}
                  onClick={(e) => {
                    if (!step.link) {
                      e.preventDefault();

                      return;
                    }

                    if (onStepClick) {
                      onStepClick(
                        e,
                        {
                          linkConfig: {
                            link,
                            search
                          },
                          step
                        }
                      );
                    }
                  }}
                >
                  <span data-content={step.label} aria-hidden={true}>{step.label}</span>
                  {step.label}
                </StepInfo>
                {floatingLabel && index === 0 && activeStep !== truncatedSteps.length && (
                  <FloatingLabel
                    left={stepWidth * truncatedActiveStep}
                  >
                    {floatingLabel}
                  </FloatingLabel>
                )}
              </StepOuter>
            </Step>
          );
        })}
      </Steps>
      <FormLine marginBottom />
      <FormLine marginBottom>
        <HR />
      </FormLine>
    </Wrapper>
  );
};

export default memo(Progress);
