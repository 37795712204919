export enum BuildingType {
  Commercial = 'COMMERCIAL',
  Residential = 'RESIDENTIAL'
};

export enum Services {
  EICR = 'EICR',
  SomethingElse = 'Other'
};

export enum PaymentOption {
  None = 'None',
  CustomerPaymentMethod = 'Card payment',
  ACHTransfer = 'Bank transfer'
};

