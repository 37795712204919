import React, { FC, useCallback, useState, useEffect, useMemo, memo } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  object,
  string
} from 'yup';

import { gatewayService } from 'services';
import { Fulfiller } from 'types/Fulfiller';
import { User } from 'types/User';
import { CellType } from 'types/Header';
import { theme } from 'theme';
import { getQueryParam } from 'utils/url';
import {
  getNetworkErrors,
  stripCountryCodeFromNumber,
  formatPhoneNumber,
  getValidPhoneNumber
} from 'utils/general';
import { Form } from 'types/UI';
import {
  FormSection,
  TextInput,
  NumericInput,
  Button,
  PrimaryButton,
  Spinner,
  Switch,
  Edit,
  Text,
  ColourPicker
} from 'components/atoms';
import { Popup } from 'components/molecules';
import {
  MenuWrapper,
  MenuItem,
  IconButton,
  StyledMenuDots
} from 'theme/mixins';
import { PageHeader } from '../../components';
import { AdminPageProps } from 'components/AppRouter';
import { canUpdateUser } from 'config/privileges';
import {
  NetworkError
} from 'types/Error';
import { useValidation } from 'components/hooks';
import { useMediaSizes } from '../../hooks';

import {
  Wrapper,
  FormWrapper,
  AdminFormLine,
  Card
} from './User.styles';

interface State {
  user: {
    loading: boolean;
    data: User | Fulfiller | null;
    fields: any;
    error: NetworkError | null;
  };
  userUpdate: {
    id: string;
    loading: boolean;
    error: NetworkError | null;
  };
  form: Form;
  ctas: {
    revokeBtnPressed: boolean;
    resetPwBtnPressed: boolean;
  };
  showActionMenu: boolean;
}

const initialState: State = {
  user: {
    loading: false,
    data: null,
    fields: {},
    error: null
  },
  userUpdate: {
    id: '',
    loading: false,
    error: null,
  },
  form: {},
  ctas: {
    revokeBtnPressed: false,
    resetPwBtnPressed: false
  },
  showActionMenu: false
};

const workforceProfileSchema = object({
  firstName: string()
    .label('First name')
    .required(),
  lastName: string()
    .label('Last name')
    .required(),
  email: string()
    .email()
    .required(),
  phone: string()
    .nullable()
    .test('phone', 'Contact number must be 10 numbers', (value) => {
      const charLength: number = stripCountryCodeFromNumber(value?.toString()?.trim() || '').length;

      if (charLength && charLength !== 10) {
        return false;
      }

      return true;
    })
    .optional(),
});

const UserPage: FC<AdminPageProps> = props => {
  const {
    userData,
    addToast
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { clientId, id } = useParams();
  const { t } = useTranslation();

  const type: string | undefined = getQueryParam('type', location.search);

  const urlAction: string | undefined = location.pathname.split('/').pop();
  const isCreateMode: boolean = urlAction === 'create';
  const isEditMode: boolean = urlAction === 'edit';
  const { isMobile, isMobileXL } = useMediaSizes();

  const [state, setState] = useState<State>({
    ...initialState,
    form: {
      ...initialState.form,
      clientId
    }
  });
  const {
    errors,
    validate,
    reset
  } = useValidation(workforceProfileSchema);

  const submitButtonDisabled = useMemo(() => {
    if (!state.user.data) {
      return true;
    }

    return JSON.stringify(state.form) === JSON.stringify(state.user.data);
  }, [
    state.form,
    state.user.data
  ]);

  const ctaPressed = useMemo(() => {
    return state.ctas.revokeBtnPressed || state.ctas.resetPwBtnPressed;
  }, [state.ctas]);

  const fetchUser = useCallback(() => {
    if (state.user.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      user: {
        ...prevState.user,
        loading: true
      }
    }));

    const apiMethod = type === 'user' ? gatewayService.getUser : gatewayService.getFulfiller;

    apiMethod(clientId!, id!)
      .then((userResponse: any) => {
        setState(prevState => ({
          ...prevState,
          user: {
            loading: false,
            data: userResponse.data,
            fields: userResponse.fields,
            error: null
          },
          ...(!isCreateMode && {
            form: userResponse.data
          })
        }));
      })
      .catch((err: any) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          user: {
            ...prevState.user,
            loading: false,
            data: null,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.user.loading,
    clientId,
    id,
    isCreateMode,
    type,
    addToast
  ]);

  const updateUser = useCallback((payload: Partial<User> | Partial<Fulfiller>, cb?: () => void) => {
    if (state.userUpdate.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      userUpdate: {
        ...prevState.userUpdate,
        loading: true,
      }
    }));

    const {
      _id,
      clientId: payloadClientId,
      created,
      updated,
      verified,
      // @ts-ignore
      isTrial,
      jobId,
      permissions,
      creator,
      creatorId,
      email,
      signedIn,
      jobsFilter,
      // @ts-ignore
      priority,
      ...rest
    } = payload;

    if (type === 'fulfiller') {
      delete rest.role;
    }

    const apiMethod = type === 'user' ? gatewayService.updateUser : gatewayService.updateFulfiller;

    apiMethod(clientId!, _id!, rest)
      .then((updateUserResponse: any) => {
        setState(prevState => ({
          ...prevState,
          user: {
            ...prevState.user,
            data: {
              ...prevState.user.data,
              ...updateUserResponse.data
            }
          },
          userUpdate: {
            ...prevState.userUpdate,
            loading: false
          },
          ...(isEditMode && {
            form: updateUserResponse.data
          }),
          ctas: {
            ...initialState.ctas
          }
        }));

        if (cb) {
          cb();
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          userUpdate: {
            ...prevState.userUpdate,
            loading: false,
            error
          },
          ctas: {
            ...initialState.ctas
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.userUpdate,
    clientId,
    isEditMode,
    type,
    addToast
  ]);

  const onChangeFormField = useCallback((e: any, key: string, value?: any) => {
    const localValue = value || value === 0 ? value : e.target.value;

    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [key]: localValue
      }
    }));
  }, []);

  const onChangeFormFieldBoolean = useCallback((newBoolState: boolean, key: string) => {
    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [key]: newBoolState
      }
    }));
  }, []);

  const toggleRevoke = useCallback(() => {
    const toastText: string = !state.user.data?.revoke ? 'revoked' : 'granted';
    const payload = {
      ...state.user.data,
      revoke: !state.user.data?.revoke
    };

    setState(prevState => ({
      ...prevState,
      ctas: {
        ...prevState.ctas,
        revokeBtnPressed: true
      }
    }));

    updateUser(payload, () => {
      addToast({
        type: 'success',
        content: `User access ${toastText}`
      });
    });
  }, [
    state.user.data,
    addToast,
    updateUser
  ]);

  const onResetPassword = useCallback(() => {
    const payload = {
      ...state.user.data,
      resetUserPassword: true
    };

    setState(prevState => ({
      ...prevState,
      ctas: {
        ...prevState.ctas,
        resetPwBtnPressed: true
      }
    }));

    updateUser(payload, () => {
      addToast({
        type: 'success',
        content: 'User password reset'
      });
    });
  }, [
    state.user.data,
    updateUser,
    addToast
  ]);

  const onFormSubmit = useCallback((e: any) => {
    e.preventDefault();

    validate({
      form: state.form,
      all: true,
      propagateRejection: true
    })
      .then(() => {
        if (isEditMode) {
          updateUser(state.form, (err?: any) => {
            if (!err) {
              navigate({
                pathname: `/${clientId}/back-office/workforce/${id}`,
                search: `?type=${type}`
              });

              addToast({
                type: 'success',
                content: 'User updated'
              });
            }
          });
        }
      })
      .catch(() => {
        addToast({
          type: 'error',
          content: 'Please ensure all required fields are filled in correctly',
          autoRemove: true
        });
      });
  }, [
    navigate,
    state.form,
    isEditMode,
    clientId,
    id,
    type,
    updateUser,
    validate,
    addToast
  ]);

  const onCancel = useCallback(() => {
    reset();

    navigate({
      pathname: `/${clientId}/back-office/workforce/${id}`,
      search: `?type=${type}`
    });
  }, [
    type,
    navigate,
    clientId,
    id,
    reset
  ]);

  const getViewModeFormValue = useCallback((key: string, value: Form['value']) => {
    switch (key) {
      case 'creatorId':
        let creator = value;

        if (state.user.data?.creator) {
          creator = `${state.user.data?.creator.firstName} ${state.user.data?.creator.lastName}`;
        }

        return creator;
      default:
        return value;
    };
  }, [state.user.data]);

  const renderForm = useCallback(() => {
    if (state.user.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (!state.user.fields || !state.user.data) {
      return null;
    }

    const formKeys = Object
      .keys(state.user.fields)
      .filter((key) => {
        if (isEditMode) {
          return key !== 'email'
            && key !== 'priority'
            && key !== 'creatorId'
            && key !== 'isTrial'
            && key !== 'jobsFilter'
            && key !== 'revoke';
        }

        return key !== 'priority' && key !== 'isTrial';
      });

    const isSmallScreen: boolean = isMobile && !isMobileXL;

    return (
      <Card>
        <AdminFormLine
          row
          centerV
          spaceBetween
          marginBottom
          style={{paddingBottom: '.5rem'}}
        >
          <h3 style={{
            marginBottom: 0
          }}>User Profile</h3>
          {isEditMode ? isSmallScreen ? (
            <IconButton
              hoverEffect
              type="button"
              onClick={(e: any) => {
                setState(prevState => ({
                  ...prevState,
                  showActionMenu: true
                }));
              }}
            >
              <StyledMenuDots />
              {state.showActionMenu && (
                <Popup
                  id="workforce-user-menu"
                  left
                  bottom
                  convertable
                  onClose={() => {
                    setState(prevState => ({
                      ...prevState,
                      showActionMenu: false
                    }));
                  }}
                >
                  {({ closePopup }) => (
                    <MenuWrapper>
                      <MenuItem onClick={() => {
                        closePopup();

                        toggleRevoke();
                      }}>{state.user.data?.revoke ? 'Allow access' : 'Revoke'}</MenuItem>
                      <MenuItem onClick={() => {
                        closePopup();

                        onResetPassword();
                      }}>Reset password</MenuItem>
                    </MenuWrapper>
                  )}
                </Popup>
              )}
            </IconButton>
          ) : (
            <AdminFormLine>
              <Button
                type={'button'}
                onClick={toggleRevoke}
                style={{
                  marginRight: '1rem',
                  marginBottom: 0
                }}
                loading={state.userUpdate.loading && state.ctas.revokeBtnPressed}
              >{state.user.data?.revoke ? 'Allow access' : 'Revoke'}</Button>
              <Button
                type={'button'}
                onClick={onResetPassword}
                style={{ marginBottom: 0 }}
                loading={state.userUpdate.loading && state.ctas.resetPwBtnPressed}
              >Reset password</Button>
            </AdminFormLine>
          ) : null}
        </AdminFormLine>
        <FormWrapper
          noOverflow
          onSubmit={onFormSubmit}
        >
          <FormSection
            cols={1}
            noBorder={!isCreateMode && !isEditMode}
            noPadding
          >
            <fieldset>
              {formKeys
                // .filter(key => key !== 'colour')
                .map((key: string, index: number) => {
                  const fieldItem = state.user.fields[key];
                  const value = (isCreateMode || isEditMode ? state.form[key] : (state.user.data as any)[key]) || '';
                  const databaseValue = (state.user.data && (state.user.data as any)[key]) || '';
                  const hasChanged: boolean = isEditMode && value !== databaseValue;

                  let elem: React.ReactNode | null = null;

                  if (key === 'creatorId' && !value.length) {
                    return elem;
                  }

                  switch (fieldItem.type) {
                    case CellType.Number:
                      if (!isCreateMode && !isEditMode) {
                        elem = (
                          <Text
                            label={t(key)}
                            value={value}
                          />
                        );
                      } else {
                        elem = (
                          <NumericInput
                            hasChanged={hasChanged}
                            value={value === '' ? false : value}
                            error={errors[key]}
                            min={1}
                            max={500}
                            label={t(key)}
                            onUpdate={(e, val: number) => onChangeFormField(e, key, val)}
                            onBlur={() => validate({
                              form: state.form,
                              field: key
                            })}
                          />
                        );
                      }
                      break;
                    case CellType.Boolean:
                      if (!isCreateMode && !isEditMode) {
                        elem = (
                          <Text
                            label={t(key)}
                            value={value === true ? 'Yes' : 'No'}
                          />
                        );
                      } else {
                        elem = (
                          <Switch
                            hasChanged={hasChanged}
                            value={value === '' ? false : value}
                            label={t(key)}
                            onChange={(newState: boolean) => onChangeFormFieldBoolean(newState, key)}
                          />
                        );
                      }
                      break;
                    case CellType.String:
                      if (key === 'colour') {
                        elem = (
                          <ColourPicker
                            disableAutoScroll
                            readOnly={!(isCreateMode || isEditMode)}
                            hasChanged={hasChanged}
                            widthM={'100%'}
                            widthML={'35rem'}
                            value={value}
                            label={t('calendarColour')}
                            onChange={(colour: any) => {
                              setState(prevState => ({
                                ...prevState,
                                form: {
                                  ...prevState.form,
                                  colour: colour.hex
                                }
                              }));
                            }}
                          />
                        );
                      } else {
                        if (!isCreateMode && !isEditMode) {
                          if (key === 'phone') {
                            elem = (
                              <Text
                                label={t(key)}
                                value={formatPhoneNumber(getViewModeFormValue(key, value) as string)}
                              />
                            );
                          } else {
                            elem = (
                              <Text
                                label={t(key)}
                                value={getViewModeFormValue(key, value)}
                              />
                            );
                          }
                        } else {
                          if (key === 'phone') {
                            elem = (
                              <TextInput
                                hasChanged={hasChanged}
                                widthM={'100%'}
                                widthML={'35rem'}
                                value={stripCountryCodeFromNumber(value)}
                                error={errors[key]}
                                label={t(key)}
                                inputMode="numeric"
                                maxLength={20}
                                inputStartAdornment={
                                  <div>+44</div>
                                }
                                onPaste={(e: any) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  const pasteString: string = e.clipboardData.getData('text');

                                  setState(prevState => ({
                                    ...prevState,
                                    form: {
                                      ...prevState.form,
                                      phone: getValidPhoneNumber(pasteString)
                                    }
                                  }));
                                }}
                                onChange={(e) => {
                                  setState(prevState => ({
                                    ...prevState,
                                    form: {
                                      ...prevState.form,
                                      phone: getValidPhoneNumber(e.target.value)
                                    }
                                  }));
                                }}
                                onBlur={() => validate({
                                  form: state.form,
                                  field: key
                                })}
                              />
                            );
                          } else {
                            elem = (
                              <TextInput
                                hasChanged={hasChanged}
                                widthM={'100%'}
                                widthML={'35rem'}
                                value={value}
                                error={errors[key]}
                                label={t(key)}
                                onChange={(e) => onChangeFormField(e, key)}
                                onBlur={() => validate({
                                  form: state.form,
                                  field: key
                                })}
                              />
                            );
                          }
                        }
                      }
                      break;
                  }

                  if (!elem) {
                    return elem;
                  }

                  return (
                    <AdminFormLine key={index} marginBottom>
                      {elem}
                    </AdminFormLine>
                  );
                })
              }
            </fieldset>
          </FormSection>
          {(isEditMode) && (
            <AdminFormLine
              topPadding
              spaceBetween
            >
              <Button
                type={'button'}
                onClick={onCancel}
              >Cancel</Button>
              <PrimaryButton
                type={'submit'}
                disabled={submitButtonDisabled}
                loading={state.userUpdate.loading && !ctaPressed}
                spinnerColor={theme.colors.coreSecondary}
              >Update user</PrimaryButton>
            </AdminFormLine>
          )}
        </FormWrapper>
      </Card>
    );
  }, [
    t,
    state,
    isCreateMode,
    isEditMode,
    isMobile,
    isMobileXL,
    errors,
    ctaPressed,
    submitButtonDisabled,
    onChangeFormField,
    onChangeFormFieldBoolean,
    onFormSubmit,
    toggleRevoke,
    onResetPassword,
    onCancel,
    validate,
    getViewModeFormValue
  ]);

  const onEdit = useCallback(() => {
    navigate({
      pathname: `/${clientId}/back-office/workforce/${id}/edit`,
      search: `?type=${type}`
    });
  }, [
    type,
    navigate,
    clientId,
    id
  ]);

  useEffect(() => {
    if (!state.user.data && !state.user.error) {
      fetchUser();
    }
  }, [
    state.user.data,
    state.user.error,
    fetchUser
  ]);

  return (
    <Wrapper>
      <PageHeader
        title={'Workforce User'}
        rightContent={
          <>
            {!isCreateMode && state.user.data && canUpdateUser(clientId!, userData.user!, state.user.data) && (
              <Button
                style={{marginBottom: 0}}
                icon={<Edit />}
                onClick={onEdit}
                disabled={isEditMode}
              >Edit</Button>
            )}
          </>
        }
      />
      {renderForm()}
    </Wrapper>
  );
};

export default memo(UserPage);

