import { Pricing } from './Pricing';
import { BaseEntity } from './Generic';

export enum FieldType {
  ShortText = 'short-text',
  LongText = 'long-text',
  Number = 'number',
  Boolean = 'boolean',
  Currency = 'currency',
  Dropdown = 'dropdown',
  Telephone = 'telephone',
  Email = 'email',
  DateTime = 'datetime'
}

export enum BookingTimeIncrements {
  Five = '5',
  Ten = '10',
  Fifteen = '15',
  Thirty = '30',
  Sixty = '60',
  OneTwenty = '120'
}

export interface DropdownItem {
  label: string;
  value: any;
}

export interface Field {
  id: string;
  label: string;
  name: string;
  type: FieldType;
  dropdownItems?: DropdownItem[];
  min?: number;
  max?: number;
  hint?: string;
  info?: string;
  calculateAfterChange?: boolean;
  value?: any;
  optional?: boolean;
  unitPrice?: number;
  unitPriceLabel?: string;
}

export interface Step {
  // id: string;
  name: string;
  label: string;
  fields: Field[];
  isFixed?: boolean;
}

export enum FulfillerAssignmentProcess {
  Cycle = 'Cycle',
  Priority = 'Priority'
}

export interface Service extends BaseEntity {
  _id: string;
  name: string;
  isEnabled: boolean;
  steps: Step[];
  fulfillerLabel: string;
  earliestBookingInDays: number;
  latestBookingInMonths: number;
  maxDuration: string;
  operatingHours: string;
  bookingTimeIncrementInMinutes: BookingTimeIncrements;
  pricingRules: Pricing[];
  concealJobPrice: boolean;
  fulfillerGroups: string[];
  fulfillerAssignmentProcess: FulfillerAssignmentProcess;
  fulfillerPayPercentage: number;
  vat: boolean;
  clientId: string;
  isDeleted: boolean;
}

export interface Builder {
  context: string;
  parentReady: boolean | null;
  selectedStepIndex: number;
  selectedFieldIndex: number;
  selectedFields: Field[];
  field: Field | null;
  selectedPrice: Pricing | null;
  isSelectable: boolean;
  isEditable: boolean;
  isSortable: boolean;
  isDeletable: boolean;
  isMultiple: boolean;
  testMode: boolean;
  hideBackground: false;
  hideFooter: false;
}

